import { http } from '../../config/http-common'

class QuestionsDataService {
  getAllQuestions() {
    return http.get<any>('/questions/')
  }

  getQuestionByBot(id_bot: string) {
    return http.get<any>(`/questions_meetings/${id_bot}/`)
  }

  sendQuestion(data: Meetings.SendQuestion) {
    return http.post<any>(`/questions/`, data)
  }
}

export default QuestionsDataService
