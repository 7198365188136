import styled from 'styled-components'

interface StatusItem {
  color?: string
}

export const StyledMeetingDayItemContent = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 15px;
  margin: 10px 0;
  cursor: pointer;

  :hover {
    background-color: #f0f1ff;
  }
`
export const StyledMeetingDayItemTitle = styled.p<StatusItem>`
  margin: 0;
  color: ${(props) => props.color};
`
export const StyledMeetingDayItemDate = styled.p`
  margin: 5px 0;
  font-size: 10px;
`
export const StyledMeetingDayItemSummary = styled.p`
  margin: 10px 0;
  font-size: 14px;
`
