import React from 'react'
import { StyledStepsContainer, StyledStepsItem } from './styledComponents'
import { MinusCircleOutlined } from '@ant-design/icons'

const Steps: React.FC = () => {
  return (
    <StyledStepsContainer>
      <StyledStepsItem>
        <MinusCircleOutlined style={{ fontSize: '20px', color: '#C9C9C9' }} />
        <p>3. Calendar Connection</p>
      </StyledStepsItem>
      <StyledStepsItem>
        <MinusCircleOutlined style={{ fontSize: '20px', color: '#C9C9C9' }} />
        <p>4. Instructions</p>
      </StyledStepsItem>
      <StyledStepsItem>
        <MinusCircleOutlined style={{ fontSize: '20px', color: '#C9C9C9' }} />
        <p> 5. Slack</p>
      </StyledStepsItem>
      <StyledStepsItem>
        <MinusCircleOutlined style={{ fontSize: '20px', color: '#C9C9C9' }} />
        <p> 6. Custom Words</p>
      </StyledStepsItem>
    </StyledStepsContainer>
  )
}

export default Steps
