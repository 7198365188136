export const PermissionsList = [
  {
    id: 'prod_OlZSQeakjavHVG',
    name: 'Pay per minute',
    access: ['meeting_search', 'edit_meeting_report', 'notifications_control']
  },
  {
    id: 'prod_Qh27w9KA83ttFr',
    name: 'Starter',
    access: ['meeting_search', 'edit_meeting_report', 'notifications_control']
  },
  {
    id: 'prod_Qh27h2le4lAG6l',
    name: 'Pro',
    access: [
      'meeting_search',
      'edit_meeting_report',
      'notifications_control',
      'integrations',
      'video',
      'custom_words',
      'tasks_detail',
      'topics_detail'
    ]
  },
  {
    id: 'prod_Qh27wDtOVJbXqB',
    name: 'Premium',
    access: [
      'meeting_search',
      'edit_meeting_report',
      'notifications_control',
      'integrations',
      'video',
      'webhooks',
      'custom_words',
      'tasks_detail',
      'topics_detail',
      'chat'
    ]
  }
]
