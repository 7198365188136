import React, { useContext } from 'react'
import HeaderComponent from '../../components/header'
import JamyContext from '../../context/jamyContext'

type PropsHeaderContainer = {
  title: string
}

const HeaderContainer: React.FC<PropsHeaderContainer> = ({ title }) => {
  const { user, logout } = useContext(JamyContext)
  const accessToken = localStorage.getItem('access-token')

  const handleLogout = () => {
    if (accessToken) {
      logout({ accesToken: accessToken })
    }
  }

  return (
    <HeaderComponent title={title} email={user?.email} logout={handleLogout} />
  )
}

export default HeaderContainer
