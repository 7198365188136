import React, { useEffect } from 'react'
import {
  StyledSettupContainer,
  StyledSettupContent,
  StyledSettupImg,
  StyledSettupText,
  StyledSettupdingH2
} from './styledComponents'
import { StyledH2 } from '../../styles/styledComponents'
import logo from '../../assets/logo/logo.png'
import ConfettiExplosion from 'react-confetti-explosion'
import { useNavigate, useParams } from 'react-router-dom'
import { Spin } from 'antd'
import UserDataService from '../../services/users'
import { toast } from 'react-toastify'

const OnboardingContainer: React.FC = () => {
  const { userId } = useParams()
  const userService = new UserDataService()
  const navigate = useNavigate()

  useEffect(() => {
    if (userId) {
      userService
        .updateUserActive({ is_validated: true }, userId)
        .then(() => {
          setTimeout(() => {
            navigate('/welcome')
          }, 2000)
        })
        .catch((error) => console.log(error))
        .finally(() =>
          toast.success('Email has been vailidated', { theme: 'colored' })
        )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  return (
    <StyledSettupContainer>
      <StyledSettupContent>
        {!userId && (
          <ConfettiExplosion
            colors={['#5333c1', '#644db9', '#5E6DB8', '#975EB8', '#E0AA25']}
          />
        )}
        <StyledSettupImg src={logo} />
        <StyledSettupdingH2>Welcome to Jamy!</StyledSettupdingH2>
        <StyledH2>
          Let's get you started with your setup so you can forget about taking
          notes by hand ;).
        </StyledH2>
        <br />
        {userId ? (
          <React.Fragment>
            <StyledSettupText>
              Thanks for validate your email in a few minutes you will be
              redirect to the configuration page.
            </StyledSettupText>
            <Spin size="large" />
          </React.Fragment>
        ) : (
          <StyledSettupText>
            {/* The first step is to connect your Google Account. We need to do this
            so we can access your Google Meet meetings, retrieve recordings from
            your Google Drive and access the Google Calendar events where you
            added <strong>notes@jamy.ai.</strong> */}
          </StyledSettupText>
        )}
      </StyledSettupContent>
    </StyledSettupContainer>
  )
}

export default OnboardingContainer
