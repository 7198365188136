import { Button, Form, FormInstance, Input, Progress } from 'antd'
import React from 'react'
import { StyledH1 } from '../../../styles/styledComponents'
import IResetPasswordUserData from '../../../types/auth/resetPassword'
import PopoverPasswords from '../../popovers/passwords'
import { StyledRegisterFormTexPassword } from '../registration/styledComponents'
import { StyledResetPasswordFormContent } from './styledComponents'
import {
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined
} from '@ant-design/icons'

type PropsResetPasswordForm = {
  form: FormInstance
  onFinish: (data: IResetPasswordUserData) => void
  onPasswordChange: (password: string) => void
  percent: number
}
const ResetPasswordForm: React.FC<PropsResetPasswordForm> = ({
  form,
  percent,
  onFinish,
  onPasswordChange
}) => {
  return (
    <StyledResetPasswordFormContent>
      <StyledH1>CHANGE PASSWORD</StyledH1>
      <Form form={form} onFinish={onFinish}>
        <Form.Item name="new_password_1" rules={[{ required: true }]}>
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Password"
            onChange={(e) => onPasswordChange(e.target.value)}
            type="password"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>
        <Form.Item name="new_password_2" rules={[{ required: true }]}>
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Repeat Password"
            type="password"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>
        <Progress percent={percent} showInfo={false} strokeColor={'#5e6bd8'} />
        <StyledRegisterFormTexPassword>
          Minimum password requirements <PopoverPasswords />
        </StyledRegisterFormTexPassword>
        <Form.Item shouldUpdate>
          {() => (
            <Button type="primary" htmlType="submit">
              CHANGE PASSWORD
            </Button>
          )}
        </Form.Item>
      </Form>
    </StyledResetPasswordFormContent>
  )
}

export default ResetPasswordForm
