import styled from 'styled-components'

export const StyledMeetingsCalendarContainerContent = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 1fr);
  gap: 5px;
  justify-items: center;
`
export const StyledMeetingCalendarSearcItem = styled.div``
export const StyledMeetingCalendarSearcItemName = styled.div``
export const StyledMeetingCalendarSearcItemDescription = styled.div``
