import { Button, Form, Input, Progress } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import {
  MailOutlined,
  UserOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined
} from '@ant-design/icons'
import React from 'react'
import {
  StyledInvitationFormContent,
  StyledInvitationFormTexPassword
} from './styledComponents'
import { StyledH1 } from '../../../styles/styledComponents'
import PopoverPasswords from '../../popovers/passwords'

type PropsInvitationForm = {
  onFinish: (data: any) => void
  onPasswordChange: (password: string) => void
  onFieldsChange: () => void
  form: FormInstance
  percentage: number
  disableButton: boolean
  email: string
}

const InvitationForm: React.FC<PropsInvitationForm> = ({
  form,
  percentage,
  disableButton,
  onFieldsChange,
  onFinish,
  onPasswordChange,
  email
}) => {
  return (
    <StyledInvitationFormContent>
      <StyledH1>INVITATION</StyledH1>
      <Form
        form={form}
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
        layout="vertical"
        initialValues={{ email: email }}
      >
        <Form.Item name="email" rules={[{ required: true }]}>
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="name@jamy.ai"
            disabled
          />
        </Form.Item>
        <Form.Item name="first_name" rules={[{ required: true }]}>
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Name"
          />
        </Form.Item>

        <Form.Item name="last_name" rules={[{ required: true }]}>
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Last name"
          />
        </Form.Item>

        <Form.Item name="password" rules={[{ required: true }]}>
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Password"
            onChange={(e) => onPasswordChange(e.target.value)}
            type="password"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>
        <Form.Item name="repeatPassword" rules={[{ required: true }]}>
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Repeat password"
            type="password"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>
        <Progress
          percent={percentage}
          showInfo={false}
          strokeColor={'#5e6bd8'}
        />
        <StyledInvitationFormTexPassword>
          Minimum password requirements <PopoverPasswords />
        </StyledInvitationFormTexPassword>
        <Form.Item shouldUpdate>
          {() => (
            <Button type="primary" htmlType="submit" disabled={disableButton}>
              CREATE ACCOUNT
            </Button>
          )}
        </Form.Item>
      </Form>
    </StyledInvitationFormContent>
  )
}

export default InvitationForm
