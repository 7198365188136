import PasswordValidator from 'password-validator'

export const ValidatePassword = (password: string) => {
  const schema = new PasswordValidator()
  schema
    .is()
    .min(8) // Minimum length 8
    .is()
    .max(100) // Maximum length 100
    .has()
    .uppercase() // Must have uppercase letters
    .has()
    .lowercase() // Must have lowercase letters
    .has()
    .digits(1) // Must have at least 2 digits
    .has()
    .not()
    .spaces() // Should not have spaces
    .is()
    .not()
    .oneOf(['Passw0rd', 'Password123']) // Blacklist these values
  return schema.validate(password)
}

export const getPercentagePassword = (password: string) => {
  let percentage = 0

  if (password.length > 8) {
    percentage = 20
  }

  if (password.toLowerCase() !== password) {
    percentage = percentage + 20
  }

  if (password.toUpperCase() !== password) {
    percentage = percentage + 20
  }

  if (/\d/.test(password)) {
    percentage = percentage + 20
  }

  if (/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password)) {
    percentage = percentage + 20
  }

  return percentage
}
