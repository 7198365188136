import React from 'react'
import { StyledCounterItem, StyledCountersContent } from './styledComponents'

export type PropsCounters = {
  counters: Array<PropsCounterItem>
}

type PropsCounterItem = {
  counter: string | number
  label: string
  color: string
}

const Counters: React.FC<PropsCounters> = ({ counters }) => {
  return (
    <StyledCountersContent>
      {counters.map((item, index) => {
        return (
          <StyledCounterItem key={index} color={item.color}>
            <p>{item.counter}</p>
            <span>{item.label}</span>
          </StyledCounterItem>
        )
      })}
    </StyledCountersContent>
  )
}

export default Counters
