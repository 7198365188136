import React from 'react'
import CardIntegration from '../../components/cardIntegration'
import webex from '../../assets/integrations/webex.svg'

const WebexIntegration: React.FC = () => {
  return (
    <CardIntegration
      category="meetings"
      description="Jamy will join and record your Webex meetings"
      name="Webex"
      isConnected={true}
      icon={webex}
    />
  )
}

export default WebexIntegration
