import React, { useContext, useState } from 'react'
import { Button, Steps } from 'antd'

import {
  StyledServicesConnectButtonContent,
  StyledServicesConnectContainer,
  StyledServicesConnectContent,
  StyledServicesConnectStepsConent
} from './styledComponents'
import MeetingsPermissionsPage from '../../pages/bots/meetings'

import SlackConnection from '../slackConnection'
import { useNavigate } from 'react-router-dom'
import JamyContext from '../../context/jamyContext'

const ServicesConnect: React.FC = () => {
  const [current, setCurrent] = useState(0)
  const navigate = useNavigate()
  const { slack_connection, user } = useContext(JamyContext)

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const steps = [
    {
      title: 'Google meet',
      content: <MeetingsPermissionsPage />
    },
    {
      title: 'Slack',
      content: <SlackConnection apps={{ slack_connection: slack_connection }} />
    }
  ]

  const items = steps.map((item) => ({ key: item.title, title: item.title }))
  return (
    <StyledServicesConnectContainer>
      <StyledServicesConnectContent>
        <>
          <Steps current={current} items={items} />
          <StyledServicesConnectStepsConent>
            {steps[current].content}
          </StyledServicesConnectStepsConent>
          <StyledServicesConnectButtonContent>
            {current < steps.length - 1 &&
              (user?.meet_user === null || user?.slack_user === null) && (
                <Button type="dashed" onClick={() => next()}>
                  Skip
                </Button>
              )}
            {current > 0 && (
              <Button
                style={{ margin: '0 8px' }}
                onClick={() => prev()}
                type="text"
              >
                Previous
              </Button>
            )}
            {user?.meet_user !== null && user?.slack_user !== null && (
              <Button
                type="primary"
                onClick={
                  current < 1
                    ? () => next()
                    : () => {
                        navigate('/bots')
                      }
                }
              >
                {current < 1 ? 'Next' : 'Done'}
              </Button>
            )}
          </StyledServicesConnectButtonContent>
        </>
      </StyledServicesConnectContent>
    </StyledServicesConnectContainer>
  )
}

export default ServicesConnect
