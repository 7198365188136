import { Button, Form, Input, Switch, Tooltip } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import JamyContext from '../../context/jamyContext'
import UserDataService from '../../services/users'
import { toast } from 'react-toastify'
import { useForm } from 'antd/es/form/Form'
import { InfoCircleOutlined } from '@ant-design/icons'
import UnlockFeature from '../../components/unlockFeature'

const NotificationsContainer: React.FC = () => {
  const [form] = useForm()
  const { user, getUser } = useContext(JamyContext)
  const userDataService = new UserDataService()
  const [automaticNotification, setAutomaticNotification] = useState(false)
  const [sendTasksUpdate, setSendTasksUpdate] = useState(false)
  const [sendTaskComplete, setSendTaskComplete] = useState(false)
  const [botChatPresentation, setBotChatPresentation] = useState('')
  const [showbotChatPresentation, setShowBotChatPresentation] = useState(false)

  useEffect(() => {
    if (user) {
      setAutomaticNotification(user.notifications.automatic_notification)
      setSendTaskComplete(user.notifications.send_task_completed)
      setSendTasksUpdate(user.notifications.send_tasks_updates)
      setBotChatPresentation(user.notifications.bot_chat_presentation)
      setShowBotChatPresentation(user.notifications.bot_chat_presentation_bool)
      form.setFieldValue(
        'bot_chat_presentation',
        user.notifications.bot_chat_presentation
      )
    }
  }, [user])

  const onChange = (typeNotification: string) => {
    switch (typeNotification) {
      case 'automatic_notification':
        userDataService
          .updateUserNotification(
            {
              automatic_notification: !automaticNotification
            },
            user ? user.id : ''
          )
          .then(() => {
            getUser()
            toast.success('Notification is updated correctly.', {
              theme: 'colored'
            })
          })
          .catch(() => {
            toast.success('Error updating notification, try again.', {
              theme: 'colored'
            })
          })
        break
      case 'send_task_completed':
        userDataService
          .updateUserNotification(
            {
              send_task_completed: !sendTaskComplete
            },
            user ? user.id : ''
          )
          .then(() => {
            getUser()
            toast.success('Notification is updated correctly.', {
              theme: 'colored'
            })
          })
          .catch(() => {
            toast.success('Error updating notification, try again.', {
              theme: 'colored'
            })
          })
        break
      case 'send_tasks_updates':
        userDataService
          .updateUserNotification(
            {
              send_tasks_updates: !sendTasksUpdate
            },
            user ? user.id : ''
          )
          .then(() => {
            getUser()
            toast.success('Notification is updated correctly.', {
              theme: 'colored'
            })
          })
          .catch(() => {
            toast.success('Error updating notification, try again.', {
              theme: 'colored'
            })
          })
        break
      case 'bot_chat_presentation_bool':
        userDataService
          .updateUserNotification(
            {
              bot_chat_presentation_bool: !showbotChatPresentation
            },
            user ? user.id : ''
          )
          .then(() => {
            getUser()
            toast.success('Notification is updated correctly.', {
              theme: 'colored'
            })
          })
          .catch(() => {
            toast.success('Error updating notification, try again.', {
              theme: 'colored'
            })
          })
        break

      default:
        break
    }
  }

  const onFinish = (data: any) => {
    userDataService
      .updateUserNotification(
        {
          bot_chat_presentation: data.bot_chat_presentation
        },
        user ? user.id : ''
      )
      .then(() => {
        getUser()
        toast.success('Notification is updated correctly.', {
          theme: 'colored'
        })
      })
      .catch(() => {
        toast.success('Error updating notification, try again.', {
          theme: 'colored'
        })
      })
  }

  return (
    <div style={{ padding: '30px' }}>
      <Form form={form} onFinish={onFinish}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>
            Send meeting report to all participants automatically after meeting
            ends.{' '}
            <Tooltip title="If disabled, organizer will be notified when meeting report is ready. Organizer can then send it manually to chosen participants">
              <InfoCircleOutlined></InfoCircleOutlined>
            </Tooltip>
          </p>
          <Form.Item name={'automatic_notification'}>
            <Switch
              checked={automaticNotification}
              onChange={() => onChange('automatic_notification')}
            />
          </Form.Item>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>
            Send individual email to participants with the tasks assigned to
            them after each meeting.
          </p>
          <Form.Item name={'send_task_completed'}>
            <Switch
              checked={sendTaskComplete}
              onChange={() => onChange('send_task_completed')}
            />
          </Form.Item>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>
            Send an email to the meeting organizer and users assigned to the
            task when you complete that task.
          </p>
          <Form.Item name={'send_tasks_updates'}>
            <Switch
              checked={sendTasksUpdate}
              onChange={() => onChange('send_tasks_updates')}
            />
          </Form.Item>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>
            Customize or deactivate the text Jamy sends in the meeting chat when
            joining.
          </p>
          <Form.Item name={'bot_chat_presentation_bool'}>
            <Switch
              checked={showbotChatPresentation}
              onChange={() => onChange('bot_chat_presentation_bool')}
            />
          </Form.Item>
        </div>
        <div hidden={!showbotChatPresentation}>
          <Form.Item name={'bot_chat_presentation'}>
            <Input.TextArea
              placeholder="Text Jamy sends"
              value={botChatPresentation}
            ></Input.TextArea>
          </Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            style={{ marginTop: '10px', width: '200px' }}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default NotificationsContainer
