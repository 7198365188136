import React from 'react'
import HelmetComponent from '../../components/helmet'
import OrganizationContainer from '../../containers/organizationContainer'
import { LayoutMain } from '../../layout'

export const OrganizationPage: React.FC = () => {
  return (
    <LayoutMain title={'ORGANIZATION'}>
      <HelmetComponent title="Organization"></HelmetComponent>
      <OrganizationContainer />
    </LayoutMain>
  )
}
