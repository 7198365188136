import styled from 'styled-components'

export const StyledBannerCalendarContent = styled.div`
  background-color: #d3d7ff;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  color: #5333c1;
  font-weight: bold;
  font-size: 25px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
`
