import React from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Popover } from 'antd'

const content = (
  <div>
    <p>8 minimum characteres</p>
    <p>1 Uppercase</p>
    <p>1 Lowercase</p>
    <p>1 number</p>
    <p>1 special character &%$_-.,</p>
  </div>
)

const PopoverPasswords: React.FC = () => {
  return (
    <Popover content={content} title="Password requirements">
      <InfoCircleOutlined />
    </Popover>
  )
}

export default PopoverPasswords
