import React from 'react'
import { AutoComplete, Input } from 'antd'
import CalendarConfiguration from '../../containers/CalendarConfiguguration'

type PropsSearchBarAutocomplete = {
  placeholder?: string
  onSelect: (value: string) => void
  handleSearch: (value: string) => void
  options: Meetings.Results[] | []
}

const SearchBarAutoComplete: React.FC<PropsSearchBarAutocomplete> = ({
  onSelect,
  placeholder,
  handleSearch,
  options
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <AutoComplete
        style={{ width: !placeholder ? '50%' : '100%' }}
        options={options}
        onSelect={onSelect}
        onSearch={handleSearch}
      >
        <Input.Search
          size="large"
          placeholder={
            placeholder
              ? placeholder
              : "Search by meeting name or participants' email"
          }
          enterButton
        />
      </AutoComplete>
      <CalendarConfiguration show={placeholder ? false : true} />
    </div>
  )
}

export default SearchBarAutoComplete
