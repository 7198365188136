import { Button } from 'antd'
import React from 'react'

type PropsValidateEmail = {
  isValidated: boolean
  onResendEmailValidation: () => void
}

const ValidateEmail: React.FC<PropsValidateEmail> = ({
  isValidated,
  onResendEmailValidation
}) => {
  return (
    <div>
      <div>
        <br />
        <p>
          {isValidated
            ? 'Your account has been validated.'
            : 'To continue you need to validate your email.'}
        </p>
      </div>
      <br />
      <div style={{ display: 'flex' }}>
        <Button
          type="primary"
          onClick={onResendEmailValidation}
          disabled={isValidated}
        >
          Resend email
        </Button>
      </div>
    </div>
  )
}

export default ValidateEmail
