import styled from 'styled-components'

export const StyledSettupContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2% 0;
`

export const StyledSettupContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
  padding: 20px 50px;
  border-radius: 30px;
`

export const StyledSettupdingH2 = styled.h2`
  font-size: 40px;
  font-weight: bold;
`
export const StyledSettupText = styled.p`
  font-size: 16px;
  line-height: 1.5;
`

export const StyledSettupImg = styled.img`
  margin: 10px 0;
  width: 50px;
`
export const StyledSettupButtonConent = styled.div`
  display: flex;
  width: 100%;
  margin: 50px 0;

  justify-content: end;

  && {
    .ant-btn {
      margin: 10px;
    }
  }
`
