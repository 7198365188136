import styled from 'styled-components'

export const StyledOrganizationContainer = styled.div`
  padding: 2%;
`
export const StyledOrganizationImg = styled.img`
  width: 100%;
`
export const StyledOrganizationText = styled.p`
  margin: 5px 0;
  font-size: 20px;
`
