import React from 'react'
import HelmetComponent from '../../components/helmet'
import { LayoutMain } from '../../layout'
import MeetingsCalendarContainer from '../../containers/meetingsCalendarContainer'

const CalendarPage: React.FC = () => {
  return (
    <LayoutMain title={'CALENDAR'}>
      <HelmetComponent title="Calendar"></HelmetComponent>
      <MeetingsCalendarContainer />
    </LayoutMain>
  )
}

export default CalendarPage
