import React from 'react'
import CardIntegration from '../../components/cardIntegration'
import zoom from '../../assets/integrations/zoom.svg'

const ZoomIntegration: React.FC = () => {
  return (
    <CardIntegration
      category="meetings"
      description="Jamy will join and record your Zoom meetings"
      name="Zoom"
      isConnected={true}
      icon={zoom}
    />
  )
}

export default ZoomIntegration
