import React from 'react'
import HelmetComponent from '../../components/helmet'
import { LayoutMain } from '../../layout'
import StripContainer from '../../containers/stripeContainer'

export const StripePage: React.FC = () => {
  return (
    <LayoutMain title={'SUBSCRIPTION'}>
      <HelmetComponent title="Subscription"></HelmetComponent>
      <StripContainer />
    </LayoutMain>
  )
}
