import React from 'react'
import { StyledH2 } from '../../styles/styledComponents'
import pdf from '../../assets/bots/pdf.png'
import csv from '../../assets/bots/csv.png'
import meet from '../../assets/bots/googlemeet.png'
import {
  StyledCreateBotsMenuContainer,
  StyledCreateBotsMenuImg,
  StyledCreateBotsMenuImgContent
} from './styledComponents'

type PropsCreateBotsMenu = {
  handleNavigationCreateBot: (bot_type: string) => void
  meetUser: string | undefined
  google_connected: boolean
}

const CreateBotsMenu: React.FC<PropsCreateBotsMenu> = ({
  handleNavigationCreateBot,
  meetUser,
  google_connected
}) => {
  return (
    <StyledCreateBotsMenuContainer>
      <StyledH2>
        {meetUser && google_connected
          ? 'You are all set to go!'
          : 'Choose bot type'}
      </StyledH2>
      <StyledCreateBotsMenuImgContent>
        <StyledCreateBotsMenuImg
          src={pdf}
          alt="PDF"
          onClick={() => handleNavigationCreateBot('PDF')}
          hidden
        />
        <StyledCreateBotsMenuImg
          src={csv}
          alt="CSV"
          onClick={() => handleNavigationCreateBot('CSV')}
          hidden
        />
        <div>
          {meetUser && google_connected && (
            <p style={{ textAlign: 'center' }}>
              The MeetBot for your organization was already created. Your are
              all set to start adding Jamy.ai to your Google Meet calls. Just
              add <strong>notes@jamy.ai</strong> as a participant!
            </p>
          )}

          {meetUser && !google_connected && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <p style={{ textAlign: 'center' }}>
                The MeetBot for your organization was already created. You just
                need to create your MeetUser to start using Jamy.ai in Google
                Meet.
              </p>
              <br />
              <StyledCreateBotsMenuImg
                src={meet}
                alt="MEET"
                onClick={() => handleNavigationCreateBot('MEETING')}
              />
            </div>
          )}

          {!meetUser && !google_connected && (
            <div>
              <StyledCreateBotsMenuImg
                src={meet}
                alt="MEET"
                onClick={() => handleNavigationCreateBot('MEETING')}
              />
            </div>
          )}
        </div>
      </StyledCreateBotsMenuImgContent>
    </StyledCreateBotsMenuContainer>
  )
}

export default CreateBotsMenu
