import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { ConfigProvider } from 'antd'
import { BrowserRouter } from 'react-router-dom'
import { theme } from './styles/customThemeAntd'
import BaseStyles from './styles/base'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Main } from './pages'
import JamyState from './context/jamyState'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ConfigProvider theme={theme}>
      <JamyState>
        <BrowserRouter>
          <BaseStyles />
          <ToastContainer />
          <Main />
          <ToastContainer />
        </BrowserRouter>
      </JamyState>
    </ConfigProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
