import React, { useContext } from 'react'
import HelmetComponent from '../../components/helmet'
import { LayoutMain } from '../../layout'
import SettingContainer from '../../containers/settingContainer'
import { StyledOnboardingPage } from './styledComponents'
import JamyContext from '../../context/jamyContext'

export const OnboardingPage: React.FC = () => {
  const { org } = useContext(JamyContext)
  return (
    <LayoutMain title={'WELCOME TO JAMY'}>
      <HelmetComponent title="Welcome"></HelmetComponent>
      <StyledOnboardingPage>
        <SettingContainer />
      </StyledOnboardingPage>
    </LayoutMain>
  )
}
