import React from 'react'
import { StyledBannerCalendarContent } from './styledComponents'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

type BannerCalendarProps = {
  next: () => void
  prev: () => void
  month: string
}

const BannerCalendar: React.FC<BannerCalendarProps> = ({
  next,
  prev,
  month
}) => {
  return (
    <StyledBannerCalendarContent>
      <LeftOutlined
        style={{ fontSize: '12px', cursor: 'pointer' }}
        onClick={prev}
      />
      {month}
      <RightOutlined
        style={{ fontSize: '12px', cursor: 'pointer' }}
        onClick={next}
      />
    </StyledBannerCalendarContent>
  )
}

export default BannerCalendar
