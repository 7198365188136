import React from 'react'
import HelmetComponent from '../../components/helmet'
import { LayoutMain } from '../../layout'
import ServicesContainer from '../../containers/servicesContainer'

export const ServicesPage: React.FC = () => {
  return (
    <LayoutMain title={'SERVICES'}>
      <HelmetComponent title="Services"></HelmetComponent>
      <ServicesContainer />
    </LayoutMain>
  )
}
