import { Button, Col, Form, FormInstance, Input, Row } from 'antd'
import React from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

type PropsRecipients = {
  form: FormInstance
  onClicEdit: (value: string) => void
  onClicDelete: (value: string) => void
  onClicFinish: (data: {
    email: string
    last_name: string
    first_name: string
  }) => void
  recipients: Meetings.Recipients[] | undefined
}

const Recipients: React.FC<PropsRecipients> = ({
  form,
  recipients,
  onClicEdit,
  onClicDelete,
  onClicFinish
}) => {
  return (
    <Form
      form={form}
      style={{ width: '500px', padding: '20px' }}
      onFinish={onClicFinish}
    >
      <Row gutter={16}>
        <Col span={22}>
          <Form.Item name={'email'}>
            <Input placeholder="Add new recipient name@jamy.ai"></Input>
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              +
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24} hidden>
          <Form.Item name={'first_name'}>
            <Input placeholder="name"></Input>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24} hidden>
          <Form.Item name={'last_name'} initialValue={''}>
            <Input placeholder="name"></Input>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          {recipients?.map((recipients, index) => {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '90%',
                  height: '40px'
                }}
                key={index}
              >
                <span>{recipients.email}</span>
                <div>
                  <EditOutlined
                    style={{ margin: '5px', cursor: 'pointer' }}
                    onClick={() => onClicEdit(recipients.email)}
                  />
                  <DeleteOutlined
                    onClick={() => onClicDelete(recipients.email)}
                    style={{ margin: '5px', cursor: 'pointer' }}
                  />
                </div>
              </div>
            )
          })}
        </Col>
      </Row>
    </Form>
  )
}

export default Recipients
