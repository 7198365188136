import React from 'react'
import {
  StyledChatWindowContainer,
  StyledChatWindowContent,
  StyledChatWindowDateMessage,
  StyledChatWindowMessageAnswer,
  StyledChatWindowMessageAnswerItem,
  StyledChatWindowMessageContent,
  StyledChatWindowMessageQuestion,
  StyledChatWindowMessageQuestionItem,
  StyledChatWindowUser
} from './styledComponents'
import { Button, Col, Form, FormInstance, Input, Row } from 'antd'
import moment from 'moment'
import ReactLoading from 'react-loading'

type PropsChatWindow = {
  form: FormInstance
  loadingQuestion: boolean
  onFinish: (data: any) => void
  questions: Array<any>
}

const ChatWindow: React.FC<PropsChatWindow> = ({
  form,
  onFinish,
  questions,
  loadingQuestion
}) => {
  return (
    <StyledChatWindowContainer>
      <StyledChatWindowContent id="chat-id">
        {questions.map((item, index) => {
          return (
            <StyledChatWindowMessageContent key={index}>
              <StyledChatWindowMessageQuestion>
                <StyledChatWindowMessageQuestionItem>
                  <StyledChatWindowUser>
                    {item.user_name ? item.user_name : 'Anonymous'}
                  </StyledChatWindowUser>
                  {item.question}
                  <StyledChatWindowDateMessage>
                    {moment(item.created_date).format('HH:mm a')}
                  </StyledChatWindowDateMessage>
                </StyledChatWindowMessageQuestionItem>
              </StyledChatWindowMessageQuestion>
              <StyledChatWindowMessageAnswer>
                <StyledChatWindowMessageAnswerItem>
                  <StyledChatWindowUser>Jamy</StyledChatWindowUser>
                  {item.answer}
                  <StyledChatWindowDateMessage>
                    {moment(item.created_date).format('HH:mm a')}
                  </StyledChatWindowDateMessage>
                </StyledChatWindowMessageAnswerItem>
              </StyledChatWindowMessageAnswer>
            </StyledChatWindowMessageContent>
          )
        })}
        {loadingQuestion && (
          <StyledChatWindowMessageContent id="chat-id">
            <StyledChatWindowMessageQuestion>
              <StyledChatWindowMessageQuestionItem>
                <ReactLoading
                  type="bubbles"
                  color="gray"
                  height={20}
                  width={20}
                />
              </StyledChatWindowMessageQuestionItem>
            </StyledChatWindowMessageQuestion>
          </StyledChatWindowMessageContent>
        )}
      </StyledChatWindowContent>
      <br />
      <Form form={form} onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={20}>
            <Form.Item name={'question'}>
              <Input placeholder="Write your question"></Input>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Button htmlType="submit" type="primary" style={{ width: '100%' }}>
              SEND
            </Button>
          </Col>
        </Row>
      </Form>
    </StyledChatWindowContainer>
  )
}

export default ChatWindow
