import { Collapse } from 'antd'
import {
  MinusCircleOutlined,
  CheckCircleFilled,
  EditOutlined
} from '@ant-design/icons'

const CollapseItem = ({ content, label, index, isActive, onChange }) => {
  return (
    <Collapse
      onChange={onChange}
      style={{ backgroundColor: 'white', border: 'none' }}
      expandIcon={() => {
        if (isActive === true) {
          return (
            <CheckCircleFilled style={{ fontSize: '20px', color: '#029A22' }} />
          )
        }

        if (isActive === false) {
          return (
            <MinusCircleOutlined
              style={{ fontSize: '20px', color: '#C9C9C9' }}
            />
          )
        }

        if (isActive === undefined) {
          return (
            <EditOutlined
              style={{ fontSize: '20px', color: 'rgb(83, 51, 193)' }}
            />
          )
        }
      }}
      items={[
        {
          key: index,
          label: label,
          children: content,
          style: {
            border: 'none'
          }
        }
      ]}
    />
  )
}

export default CollapseItem
