import React from 'react'
import HelmetComponent from '../../../components/helmet'
import CreateBotContainer from '../../../containers/createBotContainer'
import { LayoutMain } from '../../../layout'

const CreateBotPage: React.FC = () => {
  return (
    <LayoutMain title={'CREATE BOT'}>
      <HelmetComponent title="Create Bot"></HelmetComponent>
      <CreateBotContainer />
    </LayoutMain>
  )
}

export default CreateBotPage
