import React, { useEffect } from 'react'
import { StyledCalendarConnectedPermissionContainer } from './styledComponents'
import { Form, FormInstance, Select } from 'antd'
import { optionsMeetings } from '../calendarConnectedEdit/options'
import { EditOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

type PropsCalendarGeneralConnected = {
  form: FormInstance
  onFinish: (data: any) => void
  preferences: any
  outlookMail?: string
  googleMail?: string
}

interface CalendarPermissions {
  title: string
  description?: string
  value: string | boolean
}

const CalendarGeneralConnected: React.FC<PropsCalendarGeneralConnected> = ({
  form,
  onFinish,
  preferences,
  outlookMail,
  googleMail
}) => {
  const navigate = useNavigate()
  useEffect(() => {
    form.setFieldValue('join', preferences.value)
  }, [preferences])

  return (
    <StyledCalendarConnectedPermissionContainer>
      <p>Jamy will join:</p>
      <Form form={form} onFieldsChange={onFinish}>
        <Form.Item name="join">
          <Select style={{ width: '300px', marginTop: '10px' }}>
            {optionsMeetings.map((item, key) => {
              return (
                <Select.Option key={key} value={item.value}>
                  {item.text}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
      </Form>
      <div
        style={{ fontSize: '13px', display: 'flex', flexDirection: 'column' }}
      >
        <p>Connected calendar</p>
        <div style={{ display: 'flex' }}>
          <p style={{ marginTop: '5px' }}>
            <strong hidden={googleMail ? false : true}>
              Google: {googleMail !== '' ? googleMail : 'N/A'}
            </strong>
          </p>
          <p>
            <strong hidden={outlookMail ? false : true}>
              Outlook: {outlookMail ? outlookMail : 'N/A'}
            </strong>
          </p>
          <EditOutlined
            style={{ marginLeft: '5px' }}
            onClick={() => navigate('/integrations')}
            hidden={googleMail || outlookMail ? false : true}
          ></EditOutlined>
        </div>
      </div>
    </StyledCalendarConnectedPermissionContainer>
  )
}

export default CalendarGeneralConnected
