import styled from 'styled-components'

interface IPill {
  type?: 'primary' | 'secondary' | 'default'
  status?: 'to_do' | 'done'
  onClick?: () => void
}

const getColorBg = (type: 'primary' | 'secondary' | 'default' | undefined) => {
  switch (type) {
    case 'primary':
      return '#E2E7FF'
    case 'secondary':
      return '#FFF6E0'
    default:
      return '#5333C1'
  }
}

export const StyledPillContent = styled.div<IPill>`
  background-color: ${(props) => getColorBg(props.type)};
  display: flex;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  margin: 0 10px 0 0;
  height: 40px;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};

  p {
    margin: 0 0 0 5px;
    font-size: 12px;
    font-weight: 700;
    color: ${(props) => (props.type ? 'black' : 'white')};
  }

  :hover {
    box-shadow: ${(props) =>
      props.onClick ? '1px 0px 15px 5px rgba(214,214,214,0.71);' : 'none'};
    -webkit-box-shadow: ${(props) =>
      props.onClick ? '1px 0px 15px 5px rgba(214,214,214,0.71);' : 'none'};
    -moz-box-shadow: ${(props) =>
      props.onClick ? '1px 0px 15px 5px rgba(214,214,214,0.71);' : 'none'};
  }
`

export const StyledPillStatus = styled.div<IPill>`
  background-color: ${(props) =>
    props.status === 'done' ? '#5333C1' : '#FFF6E0'};
  display: flex;
  width: 150px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  margin: 0 10px 0 0;
  font-weight: bold;
  color: ${(props) => (props.status === 'done' ? 'white' : 'black')};
`
