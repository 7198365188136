import React from 'react'
import HelmetComponent from '../../../components/helmet'
import RegisterContainer from '../../../containers/registerContainer'

const RegistrationPage: React.FC = () => {
  return (
    <React.Fragment>
      <HelmetComponent title="Register"></HelmetComponent>
      <RegisterContainer />
    </React.Fragment>
  )
}

export default RegistrationPage
