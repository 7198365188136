import { Col, Row } from 'antd'
import { useForm } from 'antd/es/form/Form'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import FooterLinks from '../../components/footerLinks'
import ForgetPasswordForm from '../../components/forms/forgetPassword'
import LefSideRegister from '../../components/leftSideRegister'
import Loading from '../../components/loading'
import AuthDataService from '../../services/auth'
import IForgetPasswordUserData from '../../types/auth/forgetPassword'

const ForgetPasswordContainer: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [formForgetPassword] = useForm()
  const authService = new AuthDataService()

  const onFinish = (data: IForgetPasswordUserData) => {
    setLoading(true)
    authService
      .forgetPassword(data)
      .then(() => {
        setLoading(false)
        formForgetPassword.resetFields()
        toast.success('Email sent', { theme: 'colored' })
      })
      .catch((e) => {
        toast.error(e.response.data.non_field_errors[0], { theme: 'colored' })
        setLoading(false)
      })
  }
  return (
    <React.Fragment>
      <Row>
        <Col span={12}>
          <LefSideRegister />
          <FooterLinks />
        </Col>
        <Col span={12}>
          {!loading ? (
            <ForgetPasswordForm form={formForgetPassword} onFinish={onFinish} />
          ) : (
            <Loading />
          )}
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ForgetPasswordContainer
