import styled from 'styled-components'

interface TableCard {
  isSelected?: boolean
  isMostPopular?: boolean
}

export const StyledPricingTableCard = styled.div<TableCard>`
  background-color: ${(props) => (props.isMostPopular ? '#f1f1f1' : '#f1f1f1')}; 
  border-radius: 22px;
  margin: 30px 20px;
  width: 320px;
  text-align: center;
  position: relative;
  border: ${(props) => (props.isSelected ? '4px solid #975db8' : '')};
`

export const StyledPricingTableCardSelected = styled.div`
  height: 40px;
  width: 100%;
  background-color: #975db8;
  color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  margin-bottom: 10px;
  text-transform: uppercase;
  position: absolute;
  top: 0;
`

export const StyledPricingTableTitle = styled.h2`
  color: #975db8;
  font-size: 30px;
  font-weight: 900;
  margin-top: 40px;
  margin-bottom: 20px;
`

export const StyledPricingTablePrice = styled.div`
  font-size: 45px;
  font-weight: 900;
`

export const StyledPricingTablePriceCompare = styled.div`
  font-size: 25px;
  font-weight: 800;
  color: gray;
  text-decoration: line-through;
  margin-right: 10px;
`

export const StyledPricingTablePriceDescription = styled.p`
  font-size: 12px;
  margin: 0;
  color: #979797;
`

export const StyledPricingTableUpgradeButton = styled.button`
  background-color: #975db8;
  border: none;
  width: 70%;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px;
  margin: 20px 0;
  &:hover {
    background-color: #975db8;
  }
`

export const StyledPricingTableFeaturesListTitle = styled.p`
  font-weight: 900;
  font-size: 15px;
  margin-top: 20px;
`

export const StyledPricingTableFeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  text-align: left;
`

export const StyledPricingTableFeature = styled.li`
  font-size: 12px;
  margin-left: 45px;
`
