import React, { useContext, useState } from 'react'
import {
  StyledCardIntegrationButton,
  StyledCardIntegrationContainer,
  StyledCardIntegrationContent,
  StyledCardIntegrationDescription,
  StyledCardIntegrationImg,
  StyledCardIntegrationImgNameContent,
  StyledCardIntegrationNameIntegration
} from './styledComponents'
import UnlockFeature from '../unlockFeature'
import JamyContext from '../../context/jamyContext'

type PropsCardIntegration = {
  isConnected: boolean
  category: string
  description?: string
  name: string
  icon: string
  onClick?: () => void
}

const CardIntegration: React.FC<PropsCardIntegration> = ({
  isConnected,
  name,
  icon,
  category,
  description,
  onClick
}) => {
  const [showModal, setShowModal] = useState(false)
  const { permissions } = useContext(JamyContext)
  return (
    <StyledCardIntegrationContainer>
      <StyledCardIntegrationContent>
        <StyledCardIntegrationImgNameContent>
          <StyledCardIntegrationImg src={icon} alt={name} />
          <StyledCardIntegrationNameIntegration>
            {name}
          </StyledCardIntegrationNameIntegration>
        </StyledCardIntegrationImgNameContent>
        <StyledCardIntegrationButton
          isConnected={isConnected}
          onClick={() =>
            permissions.includes('integrations') ||
            name === 'Outlook Calendar' ||
            name === 'Google Meet'
              ? onClick
                ? onClick()
                : null
              : setShowModal(true)
          }
        >
          {isConnected ? 'Connected' : 'Add'}
        </StyledCardIntegrationButton>
      </StyledCardIntegrationContent>
      <StyledCardIntegrationDescription>
        {description}
      </StyledCardIntegrationDescription>
      <UnlockFeature
        namePlan="Pro"
        featureName={name}
        showModal={showModal}
        handleShowModal={() => {
          setShowModal(!showModal)
        }}
        handleUpgrade={() => console.log('upgrade')}
      />
    </StyledCardIntegrationContainer>
  )
}

export default CardIntegration
