export const optionsMeetings = [
  {
    value: 'all_meetings',
    text: 'All meetings',
    preferences: {
      record_non_host: false,
      record_recurring: false,
      record_external: true,
      record_internal: true,
      record_confirmed: false,
      record_only_host: false,
    }
  },
  {
    value: 'all_meetings_accepted',
    text: 'All meetings you have accepted',
    preferences: {
      record_non_host: false,
      record_recurring: false,
      record_external: true,
      record_internal: true,
      record_confirmed: true,
      record_only_host: false,
    }
  },
  {
    value: 'internal_meetings',
    text: 'Internal meetings',
    preferences: {
      record_non_host: false,
      record_recurring: false,
      record_external: false,
      record_internal: true,
      record_confirmed: false,
      record_only_host: false,
    }
  },
  {
    value: 'meeting_host',
    text: 'Meeting you are host',
    preferences: {
      record_non_host: false,
      record_recurring: false,
      record_external: true,
      record_internal: true,
      record_confirmed: false,
      record_only_host: true,
    }
  },
  {
    value: 'external_meetings',
    text: "External Meetings",
    preferences: {
      record_non_host: false,
      record_recurring: false,
      record_external: true,
      record_internal: false,
      record_confirmed: false,
      record_only_host: false,
    }
  }
]