import { Spin } from 'antd'
import React from 'react'
import { StyledLoadingContent } from './styledComponents'

const Loading: React.FC = () => {
  return (
    <StyledLoadingContent>
      <Spin size="large" />
    </StyledLoadingContent>
  )
}

export default Loading
