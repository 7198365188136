import styled from 'styled-components'

export const StyledHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const StyledHeaderLinkProfile = styled.div`
  color: #e0aa25;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;

  //lg
  @media all and (min-width: 993px) and (max-width: 1200px) {
  }
  //md
  @media all and (min-width: 769px) and (max-width: 992px) {
  }
  //sm
  @media all and (min-width: 577px) and (max-width: 768px) {
  }

  //xs
  @media all and (max-width: 576px) {
    font-size: 12px;
  }
`
