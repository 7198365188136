import { http } from '../../config/http-common'

class InvitationsDataService {
  getAllInvitations() {
    return http.get<Invitations.Response>('/invitations/')
  }
  
  getAllInvitationsByPage(page: number) {
    return http.get<Invitations.Response>(`/invitations/?page=${1}`)
  }

  createInvitation(data: Invitations.CreateInvitation) {
    return http.post<Invitations.Results>(`/invitations/`, data)
  }

  sendInvitation(data: Invitations.RegisterInvitation) {
    return http.post<any>(`/invitation/users/${data.invitation_id}/`, data)
  }

  resendInvitation(data: { invitation_id: string }) {
    return http.post<any>(`/resend_invitation/`, data)
  }

  cancelInvitation(data: { status: string }, invitation_id: string) {
    return http.patch<any>(`/invitations/${invitation_id}/`, data)
  }
}

export default InvitationsDataService
