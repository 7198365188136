import React from 'react'
import {
  StyledMeetingDayItemContent,
  StyledMeetingDayItemDate,
  StyledMeetingDayItemSummary,
  StyledMeetingDayItemTitle
} from './styledComponents'
import PopUpMeetingDetail from '../popUpMeetingDetail'
import { StyledH1 } from '../../styles/styledComponents'

type PropsMeetingDayItem = {
  title: string
  date: string
  status: string
  summary: string
  onClick: () => void
  item?: Meetings.Results
  onChange: (meeting_id?: string, override_should_record?: boolean) => void
  loading: boolean
}

const MeetingDayItem: React.FC<PropsMeetingDayItem> = ({
  title,
  date,
  item,
  onChange,
  loading,
  summary,
  status,
  onClick
}) => {
  const getColorItem = (status?: string) => {
    switch (status) {
      case 'sent':
      case 'processed':
        return onClick
      case 'not_to_be_processed':
        break
      case 'to_be_processed':
        break
    }
  }

  const getColorText = (status?: string) => {
    switch (status) {
      case 'sent':
        return '#3ba933'
      case 'processed':
        return '#975db8'
      case 'not_to_be_processed':
        return '#989b9d'
      case 'to_be_processed':
        return '#007bcd'
    }
  }

  const getToogle = (status?: string) => {
    switch (status) {
      case 'sent':
      case 'processed':
        return false
      case 'not_to_be_processed':
      case 'to_be_processed':
        return true
    }
  }

  return (
    <StyledMeetingDayItemContent onClick={getColorItem(item?.status)}>
      {item?.status === 'sent' || item?.status === 'processed' ? (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <StyledH1
              style={{ marginBottom: '15px', color: getColorText(item.status) }}
            >
              {item?.name}
            </StyledH1>
            <p
              style={{
                margin: '40px 40px 0 0',
                fontSize: '20px',
                fontWeight: 'bold',
                color: getColorText(item?.status)
              }}
            >
              {item?.status.replaceAll('_', ' ').toUpperCase()}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              flexWrap: 'wrap'
            }}
          >
            {item?.participants.map((item, index) => {
              return (
                <p key={index} style={{ margin: '0 10px 0 0' }}>
                  {item}
                </p>
              )
            })}
          </div>
          <br />
          <strong>{date}</strong>
          <StyledMeetingDayItemSummary>
            {summary !== 'undefined ....' ? summary : ''}
          </StyledMeetingDayItemSummary>
        </div>
      ) : (
        <PopUpMeetingDetail
          item={item}
          onChange={onChange}
          loading={loading}
          hidde={true}
          date={date}
        />
      )}
    </StyledMeetingDayItemContent>
  )
}

export default MeetingDayItem
