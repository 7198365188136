import React from 'react'
import { GoogleOAuthProvider } from '@react-oauth/google'
import MeetingPermissionsContainer from '../../../containers/meetingPermissionsContainer'

const MeetingsPermissionsPage: React.FC = () => {
  return (
    <GoogleOAuthProvider
      clientId={
        process.env.REACT_APP_GOOGLE_APP_CLIENT_ID
          ? process.env.REACT_APP_GOOGLE_APP_CLIENT_ID
          : ''
      }
    >
      <MeetingPermissionsContainer />
    </GoogleOAuthProvider>
  )
}

export default MeetingsPermissionsPage
