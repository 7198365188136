import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { sortBy } from 'lodash'
import React from 'react'

type PropsUsersTable = {
  data: Invitations.Response | undefined
  loading: boolean
  columns: ColumnsType<any>
  onChangePage: (e: any) => void
}

const UsersTable: React.FC<PropsUsersTable> = ({
  data,
  loading,
  columns,
  onChangePage
}) => {
  return (
    <Table
      columns={columns}
      dataSource={data ? data.results : []}
      loading={loading}
      rowKey="id"
      pagination={{
        total: data ? data.count : 0,
        defaultCurrent: 1,
        defaultPageSize: 10,
        onChange: onChangePage
      }}
    />
  )
}

export default UsersTable
