import { Button, Form, FormInstance, Input, Row } from 'antd'
import React from 'react'
import { StyledH1 } from '../../../styles/styledComponents'
import { StyledLoginFormContent, StyledLoginFormLink } from './styledComponents'
import {
  MailOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined
} from '@ant-design/icons'
import ILoginUserData from '../../../types/auth/login'
import { StyledRegisterFormLink } from '../registration/styledComponents'

type PropsLoginForm = {
  form: FormInstance
  onFinish: (data: ILoginUserData) => void
}

const LoginForm: React.FC<PropsLoginForm> = ({ form, onFinish }) => {
  return (
    <StyledLoginFormContent>
      <StyledH1>LOGIN</StyledH1>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item name="username" rules={[{ required: true }]}>
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true }]}>
          <Input.Password
            autoComplete="on"
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Password"
            type="password"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>
        <Row>
          <StyledLoginFormLink href="/forgetPassword">
            Forget password?
          </StyledLoginFormLink>
        </Row>{' '}
        <br />
        <Form.Item shouldUpdate>
          {() => (
            <Button type="primary" htmlType="submit">
              ENTER
            </Button>
          )}
        </Form.Item>
        <Row>
          <StyledRegisterFormLink href="/register">
            Don't have a Jamy account?
          </StyledRegisterFormLink>
        </Row>
      </Form>
    </StyledLoginFormContent>
  )
}

export default LoginForm
