import React from 'react'
import HelmetComponent from '../../../components/helmet'
import ForgetPasswordContainer from '../../../containers/forgetPasswordContainer'

const ForgetPasswordPage: React.FC = () => {
  return (
    <React.Fragment>
      <HelmetComponent title="Recovery Password"></HelmetComponent>
      <ForgetPasswordContainer />
    </React.Fragment>
  )
}

export default ForgetPasswordPage
