import React, { useContext, useEffect, useState } from 'react'
import PricingTable from '../../components/pricingTable'
import { Button, Modal, Segmented, Tag } from 'antd'
import Receipt from '../../components/receipt'
import PrincingDataService from '../../services/pricing'
import { toast } from 'react-toastify'
import JamyContext from '../../context/jamyContext'

const PricingTableContainer: React.FC = () => {
  const { getOrgInformation } = useContext(JamyContext)
  const [statePlan, setStatePlan] = useState('')
  const [productName, setProductName] = useState('')
  const [recurring, setRecurring] = useState('')
  const [pricingTables, setpricingTables] = useState<Princing.Table[]>([])
  const [pricingTablesConverted, setpricingTablesConverted] = useState<
    Princing.Table[]
  >([])
  const [pricingPlan, setPricingPlan] = useState<Princing.Plan>()
  const [upgradeOverview, setUpgradeOverview] =
    useState<Princing.UpgradeOverview>()
  const tablePricingDataService = new PrincingDataService()
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    getPricingPlan()
  }, [])

  useEffect(() => {
    if (pricingPlan) {
      tablePricingDataService
        .getCustomPricingTable()
        .then((response) => {
          setpricingTables(response.data.filter((x) => x.active === true))
          setRecurring(
            response.data
              .filter((x) => x.active === true)
              .filter((x) => x.id === pricingPlan?.product_id)[0]
              .prices.filter((x) => x.id === pricingPlan?.price_id)[0].recurring
              .interval === 'month'
              ? 'Monthly'
              : 'Yearly'
          )
        })
        .catch(() => {
          toast.error('Error getting custom pricing tables, try again.', {
            theme: 'colored'
          })
        })
    }
  }, [pricingPlan])

  useEffect(() => {
    if (pricingTables.length > 0) {
      addPriceInTable()
    }
  }, [recurring, pricingTables])

  const addPriceInTable = () => {
    const addPriceDefault: Princing.Table[] = []
    const pricingTablesFilter: Princing.Table[] =
      recurring === 'Yearly'
        ? pricingTables.filter((x) => x.name !== 'Pay-per-minute')
        : pricingTables
    pricingTablesFilter.forEach((element) => {
      addPriceDefault.push({
        ...element,
        default_price_converted:
          recurring === 'Monthly'
            ? (
                element.prices.filter(
                  (x) => x.recurring.interval === 'month'
                )[0].unit_amount / 100
              ).toString()
            : (
                element.prices.filter((x) => x.recurring.interval === 'year')[0]
                  .unit_amount /
                100 /
                12
              )
                .toFixed(2)
                .toString()
      })
    })
    setpricingTablesConverted(addPriceDefault)
  }

  const getPricingPlan = () => {
    tablePricingDataService
      .getPricingPlan()
      .then((response) => {
        setPricingPlan(response.data)
      })
      .catch((error) => {
        toast.error('Error getting currently plan, try again.', {
          theme: 'colored'
        })
      })
  }

  const onClickTable = (productName: string, type: string, priceID: string) => {
    setProductName(priceID)
    switch (type) {
      case 'subscribe':
        setStatePlan('subscribe')
        tablePricingDataService
          .getCheckout(productName)
          .then((response) => {
            window.open(response.data.checkout_session_url)
          })
          .catch(() => {
            toast.error('Error getting checkout, try again.', {
              theme: 'colored'
            })
          })
        break
      case 'upgrade':
        setStatePlan('upgrade')
        setIsModalOpen(true)
        tablePricingDataService
          .getUpgradeOverview(productName, priceID)
          .then((response) => {
            setUpgradeOverview(response.data)
          })
          .catch((error) => {
            toast.error('Error getting Upgrade Overview, try again.', {
              theme: 'colored'
            })
          })
        break
      default:
        break
    }
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setUpgradeOverview(undefined)
    setIsModalOpen(false)
  }

  const onClickUpgradePlan = (new_price_id: string) => {
    switch (statePlan) {
      case 'subscribe':
        tablePricingDataService
          .getCheckout(productName)
          .then((response) => {
            window.open(response.data.checkout_session_url)
          })
          .catch(() => {
            toast.error('Error getting checkout, try again.', {
              theme: 'colored'
            })
          })
        break
      case 'upgrade':
        setUpgradeOverview(undefined)

        tablePricingDataService
          .updatePricingPlan({ new_product_id: productName })
          .then((response) => {
            toast.success('Plan is updated succesfully', { theme: 'colored' })
            getPricingPlan()
            getOrgInformation()
            handleCancel()
          })
          .catch(() => {
            toast.error('Error upgrading plan', { theme: 'colored' })
          })
        break
      default:
        break
    }
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Segmented
          value={recurring}
          options={['Monthly', 'Yearly']}
          onChange={(value) => {
            setRecurring(value.toString())
          }}
        />
        <Tag
          color="orange"
          style={{
            height: '25px',
            width: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '15px',
            fontWeight: '700',
            marginLeft: '15px'
          }}
        >
          Save 25%
        </Tag>
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-around'
        }}
      ></div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-evenly',
          flexDirection: 'row-reverse'
        }}
      >
        {pricingTablesConverted.map((table, index) => {
          return (
            <PricingTable
              pricingPlanSelected={pricingPlan}
              item={table}
              recurring={recurring}
              onClickUpgrade={onClickTable}
              key={index}
            />
          )
        })}
        <Modal
          title=""
          open={isModalOpen}
          footer={null}
          onCancel={handleCancel}
          onOk={handleOk}
          width={'70%'}
        >
          <Receipt
            onClickCancel={handleCancel}
            upgradeOverview={upgradeOverview}
            onClickUpgradePlan={onClickUpgradePlan}
          />
        </Modal>
      </div>
    </div>
  )
}

export default PricingTableContainer
