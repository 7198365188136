import React, { useContext, useEffect, useState } from 'react'
import CollapseItem from '../../components/collapse'
import JamyContext from '../../context/jamyContext'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ValidateEmail from '../../components/validateEmail'
import UserDataService from '../../services/users'
import { toast } from 'react-toastify'
import CustomWordsContainer from '../customWordsContainer'
import Steps from './steps'
import OnboardingContainer from '../onboardingContainer'
import CalendarConnectionContainer from '../CalendarConnectionContainer'
import CalendarsDataService from '../../services/calendars'
import { optionsMeetings } from '../../components/calendarConnectedEdit/options'
import IntegrationsDataService from '../../services/integrations'
import { SlackIntegration } from '../integrationsContainer/slack'
import TrelloIntegration from '../integrationsContainer/trello'
import MondayIntegration from '../integrationsContainer/monday'
import { StyledH1, StyledH3 } from '../../styles/styledComponents'
import PricingTableContainer from '../pricingTableContainer'
import NotificationsContainer from '../NotificationsContainer'
import UnlockFeature from '../../components/unlockFeature'

const SettingContainer: React.FC = () => {
  const [showModal, setShowModal] = useState(false)
  const { pathname, search, hash } = useLocation()
  const {
    user,
    org,
    outlook_calendar_connection,
    google_calendar_connection,
    getUser,
    permissions
  } = useContext(JamyContext)
  const { userId } = useParams()
  const navigate = useNavigate()
  const userDataService = new UserDataService()
  const [activeKey, setActiveKey] = useState<any>()
  const calendarDataService = new CalendarsDataService()
  const showItems = user?.is_validated && org?.active ? true : false
  const [calendar, setCalendar] = useState<any>()
  const [mondayToken, setMondayToken] = useState('')
  const integrationsDataService = new IntegrationsDataService()
  const onResendEmailValidation = () => {
    userDataService
      .resendEmailVerification()
      .then(() => {
        toast.success('Email has been sent it', { theme: 'colored' })
      })
      .catch(() => {
        toast.error('Error, try again', { theme: 'colored' })
      })
  }

  useEffect(() => {
    if (search) {
      const params = search.split('?')[1].split('=')
      const calendar = {
        key: params[0],
        value: params[1] === 'true' ? true : false
      }
      if (params[0] !== 'code' && params[0] !== 'error') {
        setCalendar(calendar)
      } else {
        if (params[0] !== 'error') {
          const monday = params[1].split('&')[0]
          setMondayToken(monday)
        } else {
          navigate('/welcome')
        }
      }
    }
  }, [pathname, search])

  useEffect(() => {
    if (hash) {
      const params = hash.split('=')[1]
      integrationsDataService
        .createTrelloUser({ trello_token: params })
        .then(() => {
          toast.success('Trello is connected', {
            theme: 'colored'
          })
          navigate('/welcome')
        })
        .catch((e) => {
          console.error(e)
          toast.error('Error creating Trello user, try again.', {
            theme: 'colored'
          })
        })
    }
  }, [hash])

  useEffect(() => {
    if (mondayToken !== '') {
      integrationsDataService
        .createMondayUser({ monday_code: mondayToken })
        .then(() => {
          toast.success('Monday is connected', {
            theme: 'colored'
          })
          navigate('/welcome')
        })
        .catch((e) => {
          console.error(e)
          toast.error('Error creating Monday user, try again.', {
            theme: 'colored'
          })
        })
    }
  }, [mondayToken])

  useEffect(() => {
    if (calendar) {
      updateCalendarConnection(calendar.key, user ? user.id : '')
    }
  }, [calendar])

  const updateCalendarConnection = (calendar: string, userId: string) => {
    const id = localStorage.getItem('id')
    calendarDataService
      .updatePreferences(
        calendar === 'isConnectedGoogle'
          ? optionsMeetings[0].preferences
          : optionsMeetings[1].preferences
      )
      .then(() => {
        userDataService
          .updateUserCalendar(
            calendar === 'isConnectedGoogle'
              ? { google_calendar_connection: true }
              : { outlook_calendar_connection: true },
            id ? id : ''
          )
          .then(() => {
            setCalendar(null)
            getUser()
            toast.success(
              `${
                calendar === 'isConnectedGoogle' ? 'Google' : 'Outloook'
              } calendar is connected`,
              {
                theme: 'colored'
              }
            )
            navigate('/welcome')
          })
          .catch(() => {
            toast.error('Error Updating user, try again.', {
              theme: 'colored'
            })
          })
      })
      .catch((error) => {
        toast.error('Error updating preferences, try again.', {
          theme: 'colored'
        })
      })
  }

  useEffect(() => {
    if (activeKey === 0 || activeKey === undefined) {
      setShowModal(false)
    }

    if (activeKey === 5 && !permissions.includes('notifications_control')) {
      setShowModal(true)
    }

    if (activeKey === 4 && !permissions.includes('integrations')) {
      setShowModal(true)
    }

    if (activeKey === 6 && !permissions.includes('custom_words')) {
      setShowModal(true)
    }
  }, [activeKey])

  return (
    <div
      style={{ padding: '2% 2%', width: '100%' }}
      hidden={userId ? true : false}
    >
      <OnboardingContainer />
      <CollapseItem
        label={<strong>1. Email Verification</strong>}
        index={'1'}
        key={'email'}
        content={
          <ValidateEmail
            isValidated={user ? (user.is_validated ? true : false) : false}
            onResendEmailValidation={() => onResendEmailValidation()}
          />
        }
        isActive={user ? (user.is_validated ? true : false) : false}
        onChange={(e: any) => setActiveKey(e ? parseInt(e[0]) : undefined)}
      ></CollapseItem>
      <br />
      <CollapseItem
        label={<strong>2. Pricing</strong>}
        index={'2'}
        key={'stripe'}
        onChange={(e: any) => setActiveKey(e ? parseInt(e[0]) : undefined)}
        content={
          <React.Fragment>
            <div hidden={user ? (org?.active ? true : false) : false}>
              <StyledH1 style={{ margin: 0, textAlign: 'center' }}>
                Never Take Meeting Notes Again
              </StyledH1>
              <StyledH3 style={{ margin: '10px 0', textAlign: 'center' }}>
                Find the Perfect Plan
              </StyledH3>
              <p
                style={{
                  fontSize: '15px',
                  margin: '10px',
                  textAlign: 'center'
                }}
              >
                Find the plan that matches your needs, scales with your growth,
                and unleashes your team's true potential. Start your free trial
                today!
              </p>
            </div>
            <PricingTableContainer />
          </React.Fragment>
        }
        isActive={user ? (org?.active ? true : false) : false}
      ></CollapseItem>

      {showItems ? (
        <div>
          <br />
          <CollapseItem
            label={<strong>3. Calendar Connection</strong>}
            index={'3'}
            key={'google'}
            onChange={(e: any) => setActiveKey(e ? parseInt(e[0]) : undefined)}
            content={<CalendarConnectionContainer />}
            isActive={
              user
                ? outlook_calendar_connection || google_calendar_connection
                  ? true
                  : false
                : false
            }
          ></CollapseItem>
          <br />
          <CollapseItem
            label={<strong>4. Integrations</strong>}
            index={'4'}
            key={'slack'}
            onChange={(e: any) => setActiveKey(e ? parseInt(e[0]) : undefined)}
            content={
              <div>
                <div style={{ display: 'flex' }}>
                  <SlackIntegration />
                  <TrelloIntegration />
                  <MondayIntegration />
                </div>
              </div>
            }
            isActive={false}
          ></CollapseItem>
          <br />
          <CollapseItem
            label={<strong>5. Notifications</strong>}
            index={'5'}
            onChange={(e: any) => setActiveKey(e ? parseInt(e[0]) : undefined)}
            key={'notifications'}
            content={
              permissions && permissions.includes('notifications_control') ? (
                <NotificationsContainer />
              ) : (
                <UnlockFeature
                  namePlan="Starter"
                  featureName="Notifications"
                  showModal={showModal}
                  handleShowModal={() => {
                    setActiveKey(0)
                  }}
                  handleUpgrade={() => console.log('upgrade')}
                />
              )
            }
            isActive={false}
          ></CollapseItem>
          <br />
          <CollapseItem
            label={
              <p>
                <strong>6. Custom words</strong>{' '}
                <label style={{ fontSize: '10px' }}>*Optional</label>
              </p>
            }
            index={'6'}
            onChange={(e: any) => setActiveKey(e ? parseInt(e[0]) : undefined)}
            key={'words'}
            content={
              permissions && permissions.includes('custom_words') ? (
                <CustomWordsContainer />
              ) : (
                <UnlockFeature
                  namePlan="Pro"
                  featureName="Custom Words"
                  showModal={showModal}
                  handleShowModal={() => {
                    setActiveKey(0)
                  }}
                  handleUpgrade={() => console.log('upgrade')}
                />
              )
            }
            isActive={undefined}
          ></CollapseItem>
        </div>
      ) : (
        <Steps />
      )}
    </div>
  )
}

export default SettingContainer
