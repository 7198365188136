import React from 'react'
import { LayoutMain } from '../../../layout'
import HelmetComponent from '../../../components/helmet'
import ChatContainer from '../../../containers/chatContainer'

const ChatPage: React.FC = () => {
  return (
    <LayoutMain title="MEETING CHAT">
      <HelmetComponent title={'Meeting Chat'}></HelmetComponent>
      <ChatContainer />
    </LayoutMain>
  )
}

export default ChatPage
