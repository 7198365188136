import React from 'react'
import HelmetComponent from '../../../components/helmet'
import { InvitationContainer } from '../../../containers/invitationContainer'

const InvitationPage: React.FC = () => {
  return (
    <React.Fragment>
      <HelmetComponent title="Invitation"></HelmetComponent>
      <InvitationContainer />
    </React.Fragment>
  )
}

export default InvitationPage
