import React from 'react'
import HelmetComponent from '../../../components/helmet'
import EditBotContainer from '../../../containers/editBotContainer'
import { LayoutMain } from '../../../layout'

const EditBotPage: React.FC = () => {
  return (
    <LayoutMain title={'EDIT BOT'}>
      <HelmetComponent title="Edit Bot"></HelmetComponent>
      <EditBotContainer />
    </LayoutMain>
  )
}

export default EditBotPage
