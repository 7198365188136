import { http } from '../../config/http-common'

class SlackUsersDataService {
  getAllSlackUsers() {
    return http.get<any>(`/slack_users/`)
  }

  createSlackUser(data: any) {
    return http.post<any>(`/slack_users/`, data)
  }
}

export default SlackUsersDataService
