import { Col, Row } from 'antd'
import { useForm } from 'antd/es/form/Form'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import FooterLinks from '../../components/footerLinks'
import ResetPasswordForm from '../../components/forms/resetPassword'
import LefSideRegister from '../../components/leftSideRegister'
import Loading from '../../components/loading'
import AuthDataService from '../../services/auth'
import IResetPasswordUserData from '../../types/auth/resetPassword'
import { getPercentagePassword } from '../../utils/validatePassword'

const ResetPasswordContaine: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [percent, setPercent] = useState(0)
  const [resetPasswordForm] = useForm()
  const navigate = useNavigate()
  const authService = new AuthDataService()
  let { token } = useParams()

  const onPasswordChange = (password: string) => {
    setPercent(getPercentagePassword(password))
  }

  const onFinish = (data: IResetPasswordUserData) => {
    setLoading(true)
    data.token = token
    authService
      .resetPassword(data)
      .then(() => {
        setLoading(false)
        toast.success('Password has been changed correctly', {
          theme: 'colored'
        })
        resetPasswordForm.resetFields()
        setTimeout(() => {
          navigate('/login')
        }, 1000)
      })
      .catch((e) => {
        setLoading(false)
        toast.error('Error, try again', {
          theme: 'colored'
        })
      })
  }

  return (
    <React.Fragment>
      <Row>
        <Col span={12}>
          <LefSideRegister />
          <FooterLinks />
        </Col>
        <Col span={12}>
          {!loading ? (
            <ResetPasswordForm
              form={resetPasswordForm}
              onFinish={onFinish}
              percent={percent}
              onPasswordChange={onPasswordChange}
            />
          ) : (
            <Loading />
          )}
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ResetPasswordContaine
