import React from 'react'
import HelmetComponent from '../../components/helmet'
import BotsContainer from '../../containers/botsContainer'
import { LayoutMain } from '../../layout'

export const BotsPage: React.FC = () => {
  return (
    <LayoutMain title={'BOTS'}>
      <HelmetComponent title="Bots"></HelmetComponent>
      <BotsContainer />
    </LayoutMain>
  )
}
