import React, { useContext, useEffect, useState } from 'react'
import InvitationsDataService from '../../services/invitations'
import UsersTable from '../../components/tables/users'
import {
  Button,
  Col,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Switch,
  Tooltip
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useForm } from 'antd/es/form/Form'
import CreateInvitationForm from '../../components/createInvitationForm'
import { toast } from 'react-toastify'
import JamyContext from '../../context/jamyContext'
import UserDataService from '../../services/users'
import { StyledH2 } from '../../styles/styledComponents'
import {
  CheckCircleFilled,
  QuestionCircleFilled,
  CloseCircleFilled,
  MinusCircleFilled,
  MailFilled,
  DeleteFilled
} from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'

const UsersContainer: React.FC = () => {
  const { org, user } = useContext(JamyContext)
  const [invitations, setInvitations] = useState<Invitations.Response>()
  const [users, setUsers] = useState<any>()
  const [formInvitations] = useForm()
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [usersPage, setUsersPage] = useState(1)
  const [invitationsPage, setInvitationsPage] = useState(1)
  const invitationsService = new InvitationsDataService()
  const usersService = new UserDataService()

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    getAllUserActivesByPage(usersPage)
  }, [usersPage])

  useEffect(() => {
    getAllInvitationsByPage(invitationsPage)
  }, [invitationsPage])

  const getAllInvitationsByPage = (page: number) => {
    setLoading(true)
    invitationsService
      .getAllInvitationsByPage(page)
      .then((response) => {
        setInvitations(response.data)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }

  const getAllUserActivesByPage = (page: number) => {
    setLoading(true)
    usersService
      .getAllUsersByPage(page)
      .then((response) => {
        setUsers(response.data)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const onFinish = (data: any) => {
    handleCancel()
    setLoading(true)
    const invitation: Invitations.CreateInvitation = {
      email: data.email,
      user_type: data.user_type,
      organization: org ? org?.id : '',
      inviter: user ? user?.id : ''
    }

    invitationsService
      .createInvitation(invitation)
      .then((response) => {
        getAllInvitationsByPage(invitationsPage)
        toast.success(
          `Mail inivitation to ${response.data.email} has been sent`,
          { theme: 'colored' }
        )
        formInvitations.resetFields()
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }

  const onChange = (user: User.IUserDataResponse) => {
    const userService = new UserDataService()
    setLoading(true)
    userService
      .updateUser({ is_active: !user.is_active }, user.id)
      .then((response) => {
        setLoading(false)
        toast.success('User has been updated.', { theme: 'colored' })
      })
      .catch((error) => {
        setLoading(false)
        setUsers([])
        getAllUserActivesByPage(usersPage)
        toast.error(error.response.data.detail, { theme: 'colored' })
        console.log(error)
      })
  }

  const resendInvitation = (id: string) => {
    invitationsService
      .resendInvitation({ invitation_id: id })
      .then(() => {
        setInvitations(undefined)
        getAllInvitationsByPage(invitationsPage)
        setLoading(false)
        toast.success('Invitation has been sent it.', { theme: 'colored' })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const cancelInvitation = (id: string) => {
    invitationsService
      .cancelInvitation({ status: 'Expired' }, id)
      .then(() => {
        setInvitations(undefined)
        getAllInvitationsByPage(invitationsPage)
        setLoading(false)
        toast.success('Invitation has been canceled it.', { theme: 'colored' })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const columnsUser: ColumnsType<any> = [
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      render: (email) => email,
      width: '33%'
    },
    {
      title: 'TYPE',
      dataIndex: 'user_type',
      key: 'user_type',
      render: (id, record) => renderRadioButtonTypeUser(record),
      width: '33%'
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      render: (id, record) =>
        record.status ? (
          getIconStatus(record.status, record.id)
        ) : (
          <Tooltip title={record.is_active ? 'Active' : 'Disable'} key={id}>
            {user?.user_type !== 'OWNER' ? (
              record.is_active ? (
                <CheckCircleFilled
                  style={{ color: '#009A21', fontSize: '25px' }}
                />
              ) : (
                <CloseCircleFilled
                  style={{ color: '#9B0808', fontSize: '25px' }}
                />
              )
            ) : (
              <Switch
                defaultChecked={record.is_active}
                onChange={() => onChange(record)}
              />
            )}
          </Tooltip>
        ),
      width: '33%'
    }
  ]

  const columnsInvitations: ColumnsType<any> = [
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      render: (email) => email,
      width: '33%'
    },
    {
      title: 'TYPE',
      dataIndex: 'user_type',
      key: 'user_type',
      render: (user_type) => user_type.toUpperCase(),
      width: '33%'
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      render: (id, record) =>
        record.status ? (
          getIconStatus(record.status, record.id)
        ) : (
          <Tooltip title={record.is_active ? 'Active' : 'Disable'} key={id}>
            {user?.user_type !== 'OWNER' ? (
              record.is_active ? (
                <CheckCircleFilled
                  style={{ color: '#009A21', fontSize: '25px' }}
                />
              ) : (
                <CloseCircleFilled
                  style={{ color: '#9B0808', fontSize: '25px' }}
                />
              )
            ) : (
              <Switch
                defaultChecked={record.is_active}
                onChange={() => onChange(record)}
              />
            )}
          </Tooltip>
        ),
      width: '33%'
    }
  ]

  const onChangeT = (e: RadioChangeEvent, user: User.IUserDataResponse) => {
    const userService = new UserDataService()
    setLoading(true)
    userService
      .updateUserType({ user_type: e.target.value }, user.id)
      .then(() => {
        setLoading(false)
        getAllUserActivesByPage(usersPage)
        toast.success('User has been updated.', { theme: 'colored' })
      })
      .catch((error) => {
        setLoading(false)
        setUsers([])
        getAllUserActivesByPage(usersPage)
        toast.error(error.response.data.detail, { theme: 'colored' })
        console.log(error)
      })
  }

  const renderRadioButtonTypeUser = (user: User.IUserDataResponse) => {
    return (
      <Radio.Group onChange={(e) => onChangeT(e, user)} value={user.user_type.toUpperCase()}>
        <Radio value={'OWNER'}>Owner</Radio>
        <Radio value={'USER'}>User</Radio>
      </Radio.Group>
    )
  }

  const getIconStatus = (status: string, id: string) => {
    switch (status) {
      case 'Completed':
        return (
          <Tooltip title="Completed">
            <CheckCircleFilled style={{ color: '#009A21', fontSize: '25px' }} />
          </Tooltip>
        )
      case 'Pending':
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '50%'
            }}
          >
            <Tooltip title="Pending">
              <QuestionCircleFilled
                style={{
                  color: '#FFC100',
                  fontSize: '25px'
                }}
              />
            </Tooltip>

            <Tooltip title="Re-send Invitation">
              <MailFilled
                style={{
                  color: '#5333BE',
                  fontSize: '15px',
                  cursor: 'pointer'
                }}
                onClick={() => resendInvitation(id)}
              />
            </Tooltip>

            <Tooltip title="Cancel Invitation">
              <DeleteFilled
                style={{
                  color: '#5333BE',
                  fontSize: '15px',
                  cursor: 'pointer'
                }}
                onClick={() => cancelInvitation(id)}
              />
            </Tooltip>
          </div>
        )
      case 'Expired':
        return (
          <Tooltip title="Expired">
            <CloseCircleFilled style={{ color: '#9B0808', fontSize: '25px' }} />{' '}
          </Tooltip>
        )
      default:
        return <MinusCircleFilled />
    }
  }

  return (
    <React.Fragment>
      <Row justify="end">
        <Col>
          <Button
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            onClick={showModal}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <UsersTable
            data={users}
            loading={loading}
            columns={columnsUser}
            onChangePage={(e) => setUsersPage(e)}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <StyledH2>INVITATIONS</StyledH2>
          <UsersTable
            data={invitations}
            loading={loading}
            columns={columnsInvitations}
            onChangePage={(e) => setInvitationsPage(e)}
          />
        </Col>
      </Row>
      <Modal
        width={'70%'}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <CreateInvitationForm form={formInvitations} onFinish={onFinish} />
      </Modal>
    </React.Fragment>
  )
}

export default UsersContainer
