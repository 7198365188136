import styled from 'styled-components'

export const StyledH1 = styled.h1`
  font-size: 40px;
  font-weight: 800;
  color: #5333c1;

  //sm
  @media all and (min-width: 577px) and (max-width: 768px) {
    font-size: 35px;
  }

  //xs
  @media all and (max-width: 576px) {
    font-size: 30px;
  }
`

export const StyledH1V2 = styled.h1`
  font-size: 30px;
  font-weight: 800;
  color: black;

  //sm
  @media all and (min-width: 577px) and (max-width: 768px) {
    font-size: 30px;
  }

  //xs
  @media all and (max-width: 576px) {
    font-size: 25px;
  }
`

export const StyledH2V2 = styled.h2`
  font-size: 25px;
  font-weight: 800;
  color: black;
`

export const StyledH2 = styled.h2`
  font-size: 20px;
  color: #975eb8;
`

export const StyledH3 = styled.h3`
  font-size: 30px;
  color: black;
  text-transform: uppercase;
  font-weight: 700;
`

export const StyledH5 = styled.h5`
  font-size: 14px;
  color: rgb(224, 170, 37);
`
