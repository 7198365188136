import styled from 'styled-components'

export const StyledUserTaskContent = styled.div`
  width: 30px;
  height: 30px;
  background-color: rgba(83, 51, 193, 0.1);
  border-radius: 20px;
  border: solid 2px rgb(83, 51, 193);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  position: relative;
`
export const StyledUserTaskLabel = styled.label`
  font-size: 10px;
  font-weight: 600;
`
export const StyledUserTaskDelete = styled.div`
  position: absolute;

  border-radius: 20px;
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  color: white;
  background-color: rgb(83, 51, 193);
  top: -2px;
  font-size: 10px;
  right: -4px;
  cursor: pointer;
`
