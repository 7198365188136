import React from 'react'
import HelmetComponent from '../../../components/helmet'
import { LayoutMain } from '../../../layout'
import MeetingsDayContainer from '../../../containers/meetingsDayContainer'
import { Popover } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

const ByDayPage: React.FC = () => {
  const renderContent = () => {
    return (
      <div>
        <strong>
          A meeting has to meet the following conditions in order to be
          processed:
        </strong>
        <ol>
          <li>It has to exist in the calendar you connected to Jamy.</li>
          <li>It has to have a valid meeting link.</li>
          <li>It has to have at least two participants.</li>
        </ol>
      </div>
    )
  }
  return (
    <LayoutMain title={'CALENDAR'}>
      <HelmetComponent title="Calendar"></HelmetComponent>
      <div
        style={{ display: 'flex', justifyContent: 'end', marginBottom: '20px' }}
      >
        <Popover content={renderContent()} title="" placement="left">
          <InfoCircleOutlined />
        </Popover>
      </div>
      <MeetingsDayContainer />
    </LayoutMain>
  )
}

export default ByDayPage
