import React from 'react'
import CardIntegration from '../../components/cardIntegration'
import googleMeet from '../../assets/integrations/google_meet.svg'

const GoogleMeetIntegration: React.FC = () => {
  return (
    <CardIntegration
      category="meetings"
      description="Jamy will join and record your Meet meetings"
      name="Google Meet"
      isConnected={true}
      icon={googleMeet}
    />
  )
}

export default GoogleMeetIntegration
