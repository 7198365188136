import React, { useContext, useEffect } from 'react'
import Profile from '../../components/profile'
import JamyContext from '../../context/jamyContext'
import Loading from '../../components/loading'
import { useLocation, useNavigate } from 'react-router-dom'
import SlackUsersDataService from '../../services/slackUsers'
import { toast } from 'react-toastify'

const ProfileContainer: React.FC = () => {
  const { user, google_connection, slack_connection, org, getAllSlackUsers } =
    useContext(JamyContext)
  const navigate = useNavigate()
  const { search } = useLocation()
  const slackUserService = new SlackUsersDataService()

  useEffect(() => {
    if (search && org && !slack_connection) {
      const data: any = {
        organization: org.id,
        slack_oauth_code: search.split('=')[1].split('&')[0]
      }
      slackUserService
        .createSlackUser(data)
        .then((response) => {
          navigate('/profile')
          getAllSlackUsers()
          toast.success('Slack has been connected ', { theme: 'colored' })
        })
        .catch((error) => {
          console.log('error')
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, org])

  const onClicButtonApp = (path: string) => {
    navigate(path)
  }
  return (
    <React.Fragment>
      {user && org ? (
        <Profile
          user={user}
          org={org}
          apps={{ google_connection, slack_connection }}
          onClicButtonApp={onClicButtonApp}
        />
      ) : (
        <Loading />
      )}
    </React.Fragment>
  )
}

export default ProfileContainer
