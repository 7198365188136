import styled from 'styled-components'

export const StyledLoginFormContent = styled.div`
  padding: 20%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  &&& {
    .ant-btn-primary {
      width: 100%;
    }

    h1 {
      text-align: center;
    }
  }

  //lg
  @media all and (min-width: 993px) and (max-width: 1200px) {
  }
  //md
  @media all and (min-width: 769px) and (max-width: 992px) {
  }
  //sm
  @media all and (min-width: 577px) and (max-width: 768px) {
  }

  //xs
  @media all and (max-width: 576px) {
    padding: 10%;
  }
`
export const StyledLoginFormLink = styled.a`
  color: black;
  font-weight: 500;
  text-align: left;
`
