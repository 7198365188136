import styled from 'styled-components'

export const StyledSlackConnectionContent = styled.div`
  padding: 5%;
`
export const StyledSlackConnectionH2 = styled.h2`
  margin: 20px 0;
  font-size: 20px;
`
export const StyledSlackConnectionP = styled.p`
  line-height: 1.5;
`
export const StyledSlackConnectionUL = styled.ul``
export const StyledSlackConnectionLI = styled.li`
  line-height: 2;
`
