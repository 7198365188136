import React from 'react'
import HelmetComponent from '../../components/helmet'
import ProfileContainer from '../../containers/profileContainer'
import { LayoutMain } from '../../layout'

export const ProfilePage: React.FC = () => {
  return (
    <LayoutMain title={'PROFILE'}>
      <HelmetComponent title="Profile"></HelmetComponent>
      <ProfileContainer />
    </LayoutMain>
  )
}
