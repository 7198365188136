import styled from 'styled-components'

export const StyledForgetPasswordFormContent = styled.div`
  padding: 25%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  &&& {
    .ant-btn-primary {
      width: 100%;
    }

    h1 {
      text-align: center;
      font-size: 30px;
    }
  }
`

export const StyledForgetPasswordFormText = styled.p`
  text-align: center;
  font-size: 12px;
`
