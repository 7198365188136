import React from 'react'
import { StyledH1, StyledH3 } from '../../styles/styledComponents'
import moment from 'moment'
import { Button, Popover, Switch } from 'antd'
import Loading from '../loading'
import { InfoCircleOutlined } from '@ant-design/icons'

type PropsPopUpMeetingDetail = {
  item?: Meetings.Results
  onChange: (meeting_id?: string, override_should_record?: boolean) => void
  loading: boolean
  hidde?: boolean
  date?: string
}

const PopUpMeetingDetail: React.FC<PropsPopUpMeetingDetail> = ({
  item,
  onChange,
  loading,
  hidde,
  date
}) => {
  const getColorItem = (status?: string) => {
    switch (status) {
      case 'sent':
        return '#3ba933'
      case 'processed':
        return '#975db8'
      case 'not_to_be_processed':
        return '#989b9d'
      case 'to_be_processed':
        return '#007bcd'
    }
  }

  const getMeetingInvite = (platform?: string) => {
    switch (platform) {
      case 'google_meet':
        return item?.meet_invite
      case 'microsoft­_teams':
        return item?.teams_invite
      default:
        return
    }
  }

  const getProcess = (status?: string) => {
    switch (status) {
      case 'sent':
      case 'processed':
      case 'to_be_processed':
        return true
      case 'not_to_be_processed':
        return false
    }
  }

  const renderContent = () => {
    return (
      <div>
        <strong>
          A meeting has to meet the following conditions in order to be
          processed:
        </strong>
        <ol>
          <li>It has to exist in the calendar you connected to Jamy.</li>
          <li>It has to have a valid meeting link.</li>
          <li>It has to have at least two participants.</li>
        </ol>
      </div>
    )
  }

  return loading ? (
    <div style={{ height: '300px' }}>
      <Loading />
    </div>
  ) : (
    <div
      style={{
        padding: '20px',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
      }}
    >
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <StyledH1
              style={{ marginBottom: '0px', color: getColorItem(item?.status) }}
            >
              {item?.name}
            </StyledH1>
            <br />
            <strong>{date}</strong>
            <br />
            <br />
            <div
              style={{ display: 'flex', alignItems: 'center', width: '100%' }}
            >
              {item?.participants.map((item, index) => {
                return (
                  <p key={index} style={{ margin: '0 10px 0 0' }}>
                    {item}
                  </p>
                )
              })}
            </div>
          </div>
        </div>
        <div style={{ padding: '10px 0px', marginTop: '25px' }}>
          <StyledH3 hidden={hidde}>
            {moment(item?.start_time).format('DD MMM YYYY hh:mm a')}
          </StyledH3>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Switch
              checked={getProcess(item?.status)}
              onChange={() => onChange(item?.id, !getProcess(item?.status))}
            />
            <p
              style={{
                margin: '0 10px',
                fontWeight: 'bold',
                color: '#E0AA25',
                fontSize: '18px'
              }}
            >
              Process Meeting
            </p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'end'
              }}
            >
              <Popover content={renderContent()} title="" placement="left">
                <InfoCircleOutlined />
              </Popover>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <p
          style={{
            marginTop: '40px',
            fontSize: '20px',
            fontWeight: 'bold',
            color: getColorItem(item?.status)
          }}
        >
          {item?.status.replaceAll('_', ' ').toUpperCase()}
        </p>
        {item?.platform && (
          <Button
            htmlType="button"
            type="primary"
            onClick={() => window.open(getMeetingInvite(item?.platform))}
          >
            {item.platform.replaceAll('_', ' ').toUpperCase()}
          </Button>
        )}
      </div>
    </div>
  )
}

export default PopUpMeetingDetail
