import React, { useContext } from 'react'
import {
  StyledCalendarConnectedContent,
  StyledCalendarConnectedContentText,
  StyledCalendarConnectedHeader,
  StyledCalendarConnectedIcon,
  StyledCalendarConnectedNameCalendar,
  StyledCalendarConnectedPermissionContainer,
  StyledCalendarConnectedPermissionContent,
  StyledCalendarConnectedPermissionDescription,
  StyledCalendarConnectedPermissionTitle,
  StyledCalendarConnectedPermissionValue,
  StyledCalendarConnectedStatusCalendar
} from './styledComponents'
import { isBoolean } from 'lodash'
import CalendarConnectedEdit from '../calendarConnectedEdit'
import { FormInstance } from 'antd'
import JamyContext from '../../context/jamyContext'

type PropsCalendarConnected = {
  calendar: string
  status: boolean
  icon: string
  handleEditConfiguration: () => void
  isEdit: boolean
  form: FormInstance
  onFinish: (data: any) => void
  onCancel: () => void
  disconnectAccount: () => void
  preferences: any
}

interface CalendarPermissions {
  title: string
  description?: string
  value: string | boolean
}

const CalendarConnected: React.FC<PropsCalendarConnected> = ({
  calendar,
  status,
  icon,
  handleEditConfiguration,
  isEdit,
  form,
  disconnectAccount,
  onFinish,
  preferences,
  onCancel
}) => {
  const { user } = useContext(JamyContext)
  return (
    <StyledCalendarConnectedContent>
      <StyledCalendarConnectedHeader>
        <StyledCalendarConnectedIcon src={icon} alt={calendar} />
        <StyledCalendarConnectedContentText>
          <StyledCalendarConnectedNameCalendar>
            {calendar}
          </StyledCalendarConnectedNameCalendar>
          <StyledCalendarConnectedStatusCalendar>
            {status ? 'Connected' : 'Error connection'}
          </StyledCalendarConnectedStatusCalendar>
        </StyledCalendarConnectedContentText>
      </StyledCalendarConnectedHeader>
      {!isEdit ? (
        <StyledCalendarConnectedPermissionContainer>
          <StyledCalendarConnectedPermissionContent>
            <StyledCalendarConnectedPermissionTitle>
              Jamy will join all meetings{' '}
              <StyledCalendarConnectedPermissionValue
                onClick={handleEditConfiguration}
              >
                {preferences.text}
              </StyledCalendarConnectedPermissionValue>
            </StyledCalendarConnectedPermissionTitle>
            <br />
            <StyledCalendarConnectedPermissionDescription>
              If you don’t want us to process a meeting, just don’t let Jamy in
            </StyledCalendarConnectedPermissionDescription>
          </StyledCalendarConnectedPermissionContent>

          {/* <StyledCalendarConnectedPermissionContent>
            <StyledCalendarConnectedPermissionTitle>
              Meeting notes will be sent to host for review before sending to
              rest of participants is{' '}
              <StyledCalendarConnectedPermissionValue
                onClick={handleEditConfiguration}
              >
                {!user?.automatic_notification ? 'enabled' : 'disabled'}
              </StyledCalendarConnectedPermissionValue>
            </StyledCalendarConnectedPermissionTitle>
          </StyledCalendarConnectedPermissionContent> */}
          <br />
          <p style={{ cursor: 'pointer' }} onClick={disconnectAccount}>
            <strong>Disconnect</strong>
          </p>
        </StyledCalendarConnectedPermissionContainer>
      ) : (
        <CalendarConnectedEdit
          preferences={preferences}
          form={form}
          onFinish={onFinish}
          onCancel={onCancel}
        />
      )}
    </StyledCalendarConnectedContent>
  )
}

export default CalendarConnected
