import React from 'react'
import { StyledH2 } from '../../styles/styledComponents'
import {
  StyledSlackConnectionContent,
  StyledSlackConnectionH2,
  StyledSlackConnectionLI,
  StyledSlackConnectionP
} from './styledComponents'

type PropsSlackConnection = {
  apps: {
    slack_connection: boolean
  }
}

const SlackConnection: React.FC<PropsSlackConnection> = ({ apps }) => {
  return (
    <StyledSlackConnectionContent>
      <StyledH2>
        Jamy.ai is requesting permission to access your slack workspace
      </StyledH2>
      <br />
      <StyledSlackConnectionP>
        We have a magical Jamy Slack app that allows participants to receive
        meeting summaries in a dedicated group message. You’ll also be able to
        ask Jamy questions about the meeting right in your Slack thread.
        Experience all that Jamy has to offer:
      </StyledSlackConnectionP>
      <StyledSlackConnectionH2>
        What will Jamy.ai be able to view?
      </StyledSlackConnectionH2>

      <br />
      <ul>
        <StyledSlackConnectionLI>
          View messages and other content in public channels, private channels,
          direct messages, and group direct messages that Jamy.ai has been added
        </StyledSlackConnectionLI>
        <StyledSlackConnectionLI>
          View people in your workspace
        </StyledSlackConnectionLI>
        <StyledSlackConnectionLI>
          View email addresses of people in your workspace
        </StyledSlackConnectionLI>
      </ul>
      <br />
      <br />
      <div>
        {apps.slack_connection ? (
          <React.Fragment>
            <div
              style={{
                alignItems: 'center',
                color: '#000',
                backgroundColor: 'lightgray',
                border: '1px solid #ddd',
                borderRadius: '4px',
                display: 'inline-flex',
                fontFamily: 'Lato, sans-serif',
                fontSize: '16px',
                fontWeight: '600',
                height: '48px',
                justifyContent: 'center',
                textDecoration: 'none',
                width: '236px'
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  height: '20px',
                  width: '20px',
                  marginRight: '12px'
                }}
                viewBox="0 0 122.8 122.8"
              >
                <path
                  d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
                  fill="#e01e5a"
                ></path>
                <path
                  d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
                  fill="#36c5f0"
                ></path>
                <path
                  d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
                  fill="#2eb67d"
                ></path>
                <path
                  d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
                  fill="#ecb22e"
                ></path>
              </svg>
              Add to Slack
            </div>
            <p
              style={{ marginTop: '10px', fontSize: '12px' }}
              hidden={!apps.slack_connection}
            >
              Connected
            </p>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <a
              href="https://slack.com/oauth/v2/authorize?client_id=5124423588130.5121502332821&scope=app_mentions:read,channels:history,channels:join,files:write,groups:history,im:history,im:write,incoming-webhook,mpim:history,mpim:write,users:read,users:read.email,chat:write&user_scope="
              style={{
                alignItems: 'center',
                color: '#000',
                backgroundColor: '#fff',
                border: '1px solid #ddd',
                borderRadius: '4px',
                display: 'inline-flex',
                fontFamily: 'Lato, sans-serif',
                fontSize: '16px',
                fontWeight: '600',
                height: '48px',
                justifyContent: 'center',
                textDecoration: 'none',
                width: '236px'
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  height: '20px',
                  width: '20px',
                  marginRight: '12px'
                }}
                viewBox="0 0 122.8 122.8"
              >
                <path
                  d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
                  fill="#e01e5a"
                ></path>
                <path
                  d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
                  fill="#36c5f0"
                ></path>
                <path
                  d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
                  fill="#2eb67d"
                ></path>
                <path
                  d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
                  fill="#ecb22e"
                ></path>
              </svg>
              Add to Slack
            </a>
            <p
              style={{ marginTop: '10px', fontSize: '12px' }}
              hidden={!apps.slack_connection}
            >
              Connected
            </p>
          </React.Fragment>
        )}
      </div>
    </StyledSlackConnectionContent>
  )
}

export default SlackConnection
