import { http } from '../../config/http-common'

class OrgsDataService {
  getOrganization() {
    return http.get<Orgs.IOrgsResponse>('/organizations/')
  }

  updateCustomWords(data: Orgs.CustomWords[], org_id: string) {
    return http.patch<Orgs.IOrgsResponse>(`/organizations/${org_id}/`, {
      custom_words: data
    })
  }
}

export default OrgsDataService
