import styled from 'styled-components'

interface CounterItemProps {
  color: string
}

export const StyledCountersContent = styled.div`
  display: flex;
  width: 100%;
  //lg
  @media all and (min-width: 993px) and (max-width: 1200px) {
  }
  //md
  @media all and (min-width: 769px) and (max-width: 992px) {
  }
  //sm
  @media all and (min-width: 577px) and (max-width: 768px) {
  }

  //xs
  @media all and (max-width: 576px) {
    flex-direction: column;
  }
`
export const StyledCounterItem = styled.div<CounterItemProps>`
  width: 100%;
  height: 150px;
  padding: 20px;
  border-radius: 10px;
  margin: 10px 30px 10px 0;
  background-color: ${(props) => props.color};
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  p {
    font-size: 35px;
    font-weight: bold;
    margin: 10px 0;
  }

  span {
    font-size: 12px;
  }

  //xs
  @media all and (max-width: 576px) {
    height: 100px;

    p {
      font-size: 30px;
      font-weight: bold;
      margin: 10px 0;
    }

    span {
      font-size: 10px;
    }
  }
`
