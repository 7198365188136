import styled from "styled-components";

export const StyledCalendarConnectionContainer = styled.div`
  padding: 30px;
`
export const StyledCalendarConnectionTitle = styled.h3`
  font-weight: bold;
  font-size: 20px;
  margin: 0;
`
export const StyledCalendarConnectionDescription = styled.p`
  font-size: 18px;
  color: #975DB8;
  margin: 0;
`
export const StyledCalendarConnectionBox = styled.div`
  display: flex;

`
