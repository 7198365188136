import styled from "styled-components";

export const RecordingButton = styled.button`
  display: flex;
  align-items: center;
  background-color: rgb(83, 51, 193);
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: bold;
  color: #000;
  color:  white;
  cursor: pointer;


  :hover {
    box-shadow: 1px 0px 15px 5px rgba(214,214,214,0.71);
  }
  
`;