import styled from 'styled-components'

export const StyledLeftSideRegisterContent = styled.div`
  background-color: rgba(83, 51, 193, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  @media all and (min-width: 768px) and (max-width: 1024px) {
  }

  @media all and (min-width: 480px) and (max-width: 768px) {
    height: 10vh;
  }

  @media all and (max-width: 480px) {
    height: 10vh;
  }
`
export const StyledLeftSideRegisterImg = styled.img`
  position: absolute;
  top: 0;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    width: 80%;
  }

  @media all and (min-width: 480px) and (max-width: 768px) {
  }

  @media all and (max-width: 480px) {
    width: 100%;
  }
`
export const StyledLeftSideRegisterLogo = styled.img`
  width: 190px;
  height: 180px;

  @media all and (min-width: 768px) and (max-width: 1024px) {
    width: 140px;
    height: 130px;
  }

  @media all and (min-width: 480px) and (max-width: 768px) {
    display: none;
  }

  @media all and (max-width: 480px) {
    display: none;
  }
`
