import axios from 'axios'

export function createAxiosClient() {
  const http = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: { 'Content-type': 'application/json' }
  })

  http.interceptors.request.use(
    (config) => {
      if (config.headers.Authorization !== false) {
        if (localStorage.getItem('access-token')) {
          config.headers.Authorization =
            'Token ' + localStorage.getItem('access-token')
        }
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  return http
}

export const http = createAxiosClient()
