import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

type HelmetProps = {
  title: string
}

const HelmetComponent: React.FC<HelmetProps> = ({ title }) => {
  const defaultTitle = 'Jamy'
  return (
    <HelmetProvider>
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        title={title}
        titleTemplate={`%s | ${defaultTitle}`}
      >
        <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-PBWD8TL9CV"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-PBWD8TL9CV');`}
        </script>
      </Helmet>
    </HelmetProvider>
  )
}

export default HelmetComponent
