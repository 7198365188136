import React, { ReactNode } from 'react'
import { StyledMessageBox } from './styledComponents'

type PropsMessageBox = {
  messageBox: ReactNode
}
const MessageBox: React.FC<PropsMessageBox> = ({ messageBox }) => {
  return messageBox ? (
    <StyledMessageBox>{messageBox}</StyledMessageBox>
  ) : (
    <div></div>
  )
}

export default MessageBox
