import React from 'react'
import { InboxOutlined } from '@ant-design/icons'
import { Upload } from 'antd'

const { Dragger } = Upload

type PropsUploadDragger = {
  loadDocuments: (info: any) => void
}

const UploadDragger: React.FC<PropsUploadDragger> = ({ loadDocuments }) => {
  return (
    <Dragger
      onChange={(info) => loadDocuments(info)}
      beforeUpload={() => {
        return false
      }}
      multiple={false}
    >
      <div>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading
          company data or other band files
        </p>
      </div>
    </Dragger>
  )
}

export default UploadDragger
