import { Button, Col, Form, FormInstance, Input, InputNumber, Row } from 'antd'
import React from 'react'
import UploadDragger from '../upload'
import {
  StyledEditBotForm,
  StyledEditBotFormContentImg,
  StyledEditBotFormDocumentsContent,
  StyledEditBotFormDocumentsImg,
  StyledEditBotFormDocumentsLink,
  StyledEditBotFormInstructionsContent,
  StyledEditBotFormInstructionsImg,
  StyledEditBotFormInstructionsText,
  StyledEditBotFormTitles
} from './styledComponents'
import clip from '../../assets/icons/clip.png'
import bote from '../../assets/icons/delete.png'
import { StyledCreateBotFormImg } from '../createBotForm/styledComponents'
import bots from '../../assets/bots/bot.png'

type PropsEditBotForm = {
  form: FormInstance
  bot: Bots.IBotsResults | undefined
  documents: string[]
  instructions: string[]
  onChangeInstuction: (value: string) => void
  onFinish: (data: any) => void
  addinstruction: () => void
  deleteInstruction: (value: String) => void
}

const EditBotForm: React.FC<PropsEditBotForm> = ({
  form,
  documents,
  bot,
  instructions,
  onChangeInstuction,
  onFinish,
  addinstruction,
  deleteInstruction
}) => {
  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={6} lg={8}>
        <StyledEditBotFormContentImg>
          <StyledCreateBotFormImg src={bots} />
        </StyledEditBotFormContentImg>
      </Col>
      <Col xs={24} sm={24} md={18} lg={16}>
        <Form
          form={form}
          layout="inline"
          onFinish={onFinish}
          initialValues={bot}
        >
          <StyledEditBotForm>
            <div>
              <Row gutter={16}>
                <StyledEditBotFormTitles>
                  General Information
                </StyledEditBotFormTitles>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item name="name">
                    <Input placeholder="Bot name" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6}>
                  <Form.Item name="temperature">
                    <InputNumber
                      min={0.0}
                      max={1}
                      step={0.1}
                      placeholder="Temperature"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6}>
                  <Form.Item name="certainty">
                    <InputNumber
                      min={0.0}
                      max={1}
                      step={0.1}
                      placeholder="Certainty"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <br />
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item name="description">
                    <Input.TextArea placeholder="Description" />
                  </Form.Item>
                </Col>
              </Row>{' '}
              <br />
              {bot?.bot_type !== 'MEETING' && (
                <React.Fragment>
                  <Row gutter={16}>
                    <StyledEditBotFormTitles>
                      Upload documents (Format PDF or CSV)
                    </StyledEditBotFormTitles>
                    <Row gutter={16} style={{ width: '100%' }}>
                      <Col span={24}>
                        <UploadDragger
                          loadDocuments={(info) => console.log(info)}
                        ></UploadDragger>
                      </Col>
                    </Row>
                  </Row>
                  <Row>
                    <Col>
                      {documents.map((item, index) => {
                        return (
                          <StyledEditBotFormDocumentsContent key={index}>
                            <StyledEditBotFormDocumentsImg src={clip} />
                            <StyledEditBotFormDocumentsLink>
                              {item.split('/').pop()}
                            </StyledEditBotFormDocumentsLink>
                            <StyledEditBotFormDocumentsImg src={bote} />
                          </StyledEditBotFormDocumentsContent>
                        )
                      })}
                    </Col>
                  </Row>
                </React.Fragment>
              )}
              <Row gutter={1}>
                <Col span={12}>
                  <Form.Item name="instruction">
                    <Input
                      placeholder="Instructions"
                      onChange={(e) => onChangeInstuction(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={addinstruction}
                  >
                    +
                  </Button>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  {instructions.map((item, index) => {
                    return (
                      <StyledEditBotFormInstructionsContent key={index}>
                        <StyledEditBotFormInstructionsText>
                          {item}
                        </StyledEditBotFormInstructionsText>
                        <StyledEditBotFormInstructionsImg
                          src={bote}
                          onClick={() => deleteInstruction(item)}
                        />
                      </StyledEditBotFormInstructionsContent>
                    )
                  })}
                </Col>
              </Row>
              <br />
            </div>
            <div>
              <Row gutter={16} justify="end">
                <Col span={6}>
                  <Button
                    type="default"
                    htmlType="button"
                    style={{ width: '100%' }}
                  >
                    CANCEL
                  </Button>
                </Col>
                <Col span={6}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: '100%' }}
                  >
                    SAVE
                  </Button>
                </Col>
              </Row>
            </div>
          </StyledEditBotForm>
        </Form>
      </Col>
    </Row>
  )
}

export default EditBotForm
