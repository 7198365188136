import styled from 'styled-components'

interface ICardIntegration {
  isConnected: boolean
}

export const StyledCardIntegrationContainer = styled.div`
  width: 250px;
  height: 150px;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  background-color: white;
  justify-content: space-between;
  align-items: center;
`
export const StyledCardIntegrationContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const StyledCardIntegrationDescription = styled.p`
  font-size: 12px;
  margin: 0 10px;
  line-height: 15px;
`

export const StyledCardIntegrationImgNameContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`
export const StyledCardIntegrationImg = styled.img`
  width: 50px;
  height: 40px;
  margin: 5px;
`
export const StyledCardIntegrationNameIntegration = styled.p`
  font-weight: bold;
  text-align: center;
`
export const StyledCardIntegrationButton = styled.button<ICardIntegration>`
  height: 30px;
  width: 90px;
  border-radius: 50px;
  border: ${(props) => (props.isConnected ? 'none' : '1px solid #5333c1')};
  color: ${(props) => (props.isConnected ? '#E0AA25' : '#5333c1')};
  background-color: transparent;
  font-weight: 600;
  cursor: ${(props) => (props.isConnected ? 'default' : 'pointer')};

  :hover {
    background-color: '#5333C1';
  }
`
