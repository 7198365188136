import React from 'react'
import HelmetComponent from '../../components/helmet'
import { LayoutMain } from '../../layout'
import IntegrationsContainer from '../../containers/integrationsContainer'

export const IntegrationsPage: React.FC = () => {
  return (
    <LayoutMain title={'INTEGRATIONS'}>
      <HelmetComponent title="Integrations"></HelmetComponent>
      <IntegrationsContainer />
    </LayoutMain>
  )
}
