import styled from 'styled-components'

export const StyledServicesConnectContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledServicesConnectContent = styled.div`
  width: 90%;
  background-color: white;
  padding: 50px;
  border-radius: 20px;
`
export const StyledServicesConnectStepsConent = styled.div`
  padding: 10px;
  margin: 30px 0;
`
export const StyledServicesConnectButtonContent = styled.div`
  display: flex;

  justify-content: end;
  padding: 10px;
`
export const StyledServicesConnect4 = styled.div``
