import React, { useContext, useEffect, useState } from 'react'
import JamyContext from '../../context/jamyContext'
import CustomWords from '../../components/customWords'
import { useForm } from 'antd/es/form/Form'
import Loading from '../../components/loading'
import OrgsDataService from '../../services/org'
import { toast } from 'react-toastify'
import { Button, Col, Form, Input, Row } from 'antd'
import { StyledH2 } from '../../styles/styledComponents'
import SearchBarAutoComplete from '../../components/searchBarAutocomplete'
import MeetingsDataService from '../../services/meetings'
import { useParams } from 'react-router-dom'

const CustomWordsContainer: React.FC = () => {
  const { org, getOrgInformation } = useContext(JamyContext)
  const [loading, setLoading] = useState(false)
  const { meeting_id } = useParams()
  const [newWord, setNewWord] = useState(false)
  const [searchWord, setSearchWord] = useState('')
  const [wordSelected, setWordSelected] = useState('')
  const [customWords, setCustomWords] = useState<any[]>([])
  const [optionsWords, setOptionsWords] = useState<any>([])
  const [word, setWord] = useState('')
  const [form] = useForm()
  const [addForm] = useForm()
  const orgServiceData = new OrgsDataService()
  const meetingServiceData = new MeetingsDataService()

  useEffect(() => {
    if (searchWord !== '') {
      filterWords(searchWord)
      let op: any[] = []
      op.push({
        label: (
          <p style={{ margin: 0 }}>
            <strong>Add new custom word</strong>
          </p>
        ),
        value: '0'
      })
      filterWords(searchWord).forEach((element) => {
        op.push({ label: element.word, value: element.word })
      })
      setOptionsWords(op)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWord, org])

  useEffect(() => {
    const cw = org?.custom_words.filter((x) => x.word === wordSelected)
    setCustomWords(cw ? cw : [])
  }, [wordSelected, org])

  const onFinishWord = (data: any) => {
    const wordsData: Orgs.CustomWords[] = org ? org?.custom_words : []
    const wordsIncorrectSelected = wordsData?.filter((x) => x.word === word)[0]
    wordsIncorrectSelected?.incorrects.push(data.customWord)
    if (meeting_id) {
      proccesMeetingAfterAddWords(meeting_id, wordsData)
    }
    orgServiceData
      .updateCustomWords(wordsData, org ? org.id : '')
      .then(() => {
        setLoading(false)
        getOrgInformation()
        meetingServiceData.getMeetingById(meeting_id ? meeting_id : '')
        toast.success('Custom word is added', { theme: 'colored' })
      })
      .catch(() => {
        setLoading(false)
        toast.error('Error, try again', { theme: 'colored' })
      })
  }

  const deleteItem = (incorrect: string, word: string) => {
    setLoading(true)
    const wordsData: Orgs.CustomWords[] = org ? org?.custom_words : []
    wordsData.filter((x) => x.word === word)[0].incorrects = wordsData
      ?.filter((x) => x.word === word)[0]
      .incorrects.filter((x) => x !== incorrect)

    orgServiceData
      .updateCustomWords(wordsData, org ? org.id : '')
      .then(() => {
        setLoading(false)
        getOrgInformation()
        toast.success('Custom word is deleted', { theme: 'colored' })
      })
      .catch(() => {
        setLoading(false)
        toast.error('Error, try again', { theme: 'colored' })
      })
  }

  const onFinishAdd = (data: any) => {
    setLoading(true)
    setWordSelected(data.word)
    const wordsData: Orgs.CustomWords[] = org ? org?.custom_words : []
    wordsData.push({
      word: data.word,
      incorrects: [data.incorrect]
    })
    if (meeting_id) {
      proccesMeetingAfterAddWords(meeting_id, wordsData)
    }
    orgServiceData
      .updateCustomWords(wordsData, org ? org.id : '')
      .then(() => {
        setLoading(false)
        getOrgInformation()
        form.resetFields()
        setNewWord(false)
        meetingServiceData.getMeetingById(meeting_id ? meeting_id : '')
        toast.success('Custom word is added', { theme: 'colored' })
      })
      .catch(() => {
        setLoading(false)
        toast.error('Error, try again', { theme: 'colored' })
      })
  }

  const proccesMeetingAfterAddWords = (
    meeting_id: string,
    custom_words: Orgs.CustomWords[]
  ) => {
    const data = {
      meeting_id: meeting_id,
      custom_words: custom_words
    }
    meetingServiceData
      .processMeeting(data)
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const filterWords = (searchBy: string): any[] => {
    const data = org?.custom_words.filter(
      (element) =>
        element.word
          .toLocaleLowerCase()
          .indexOf(searchBy.toLocaleLowerCase()) !== -1
    )
    return data ? data : []
  }

  return (
    <React.Fragment>
      <div style={{ maxWidth: '700px', padding: '20px' }}>
        <StyledH2>Add your organization's custom words</StyledH2>
        <p>
          Train Jamy with the custom words your organization uses so correct
          spelling is always guaranteed and is automatically corrected in this
          report.
        </p>
        <strong>STEPS</strong>
        <ol style={{ marginTop: '10px', paddingInlineStart: '20px' }}>
          <li>
            Search or add the <strong>correct spelling</strong> of your custom
            word by typing it in the search bar
          </li>
          <li>Add incorrect spellings associated to that custom word.</li>
        </ol>
      </div>
      <div style={{ width: '100%' }}>
        {!newWord ? (
          <SearchBarAutoComplete
            placeholder="Type the correct spelling of your custom word"
            onSelect={(value) => {
              if (value === '0') {
                setNewWord(true)
              } else {
                setWordSelected(value)
              }
            }}
            handleSearch={(value) => {
              setSearchWord(value)
            }}
            options={optionsWords}
          />
        ) : (
          <div style={{ width: '100%' }}>
            <Form layout="horizontal" form={addForm} onFinish={onFinishAdd}>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item name="word" initialValue={searchWord}>
                    <Input placeholder="Correct Word" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="incorrect">
                    <Input placeholder="Incorrect Word" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Button type="primary" htmlType="submit">
                    +
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </div>
      {!loading ? (
        <div>
          {customWords.map((item, index) => {
            return (
              <div key={index}>
                <CustomWords
                  custom_words={item.incorrects}
                  form={form}
                  onFinishWord={onFinishWord}
                  word={item.word}
                  onClicItem={setWord}
                  deleteItem={deleteItem}
                />
              </div>
            )
          })}
          <br />
        </div>
      ) : (
        <Loading />
      )}
    </React.Fragment>
  )
}

export default CustomWordsContainer
