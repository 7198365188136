import React, { useState, useEffect, useContext } from 'react'
import { useGoogleLogin } from '@react-oauth/google'
import axios from 'axios'
import {
  StyledMeetBotContainer,
  StyledMeetBotPermissionContent,
  StyledMeetBotPermissionLink,
  StyledMeetBotPermissionP,
  StyledMeetBotPermissionText
} from './styledComponents'
import { StyledH2 } from '../../styles/styledComponents'
import MeetUsersDataService from '../../services/meetUsers'
import { toast } from 'react-toastify'
import JamyContext from '../../context/jamyContext'
import google from '../../assets/icons/google.png'
import BotsDataService from '../../services/bots'
import Loading from '../../components/loading'

function MeetingPermissionsContainer() {
  const { org, user, getUser } = useContext(JamyContext)
  const initialUserProperties = {
    access_token: '',
    expires_in: 0,
    id_token: '',
    scope: '',
    token_type: ''
  }
  const SCOPES = process.env.REACT_APP_GOOGLE_APP_SCOPES
  const [emailUser, setEmailUser] = useState<any>(initialUserProperties)
  const [, setEmailProfile] = useState<any>(null)
  const meetUserService = new MeetUsersDataService()
  const botService = new BotsDataService()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
  }, [user])

  useEffect(() => {
    if (!!emailUser.access_token) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${emailUser.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${emailUser.access_token}`,
              Accept: 'application/json'
            }
          }
        )
        .then((res) => {
          setEmailProfile(res.data)
        })
        .catch((err) => console.log('err: ', err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailUser])

  const formDataConvert = () => {
    let formData = new FormData()
    formData.append('name', `${org?.name}MeetBot`)
    formData.append('temperature', '0.2')
    formData.append('organization', org ? org.id : '')
    formData.append('bot_type', 'MEETING')
    formData.append('certainty', '80')
    formData.append(
      'dont_know_response',
      `I don't have enough information to answer that question. Please try another one or try adding more detail.`
    )
    formData.append('description', `${org?.name} Google Meet Bot`)
    formData.append('instructions', 'Answer factually in less than 100 words.')

    return formData
  }
  const createMeetUser = (data: any) => {
    meetUserService
      .createMeetUser(data)
      .then((response) => {
        toast.success('Meet user has been created', { theme: 'colored' })
        getUser()
      })
      .catch((e: any) => {
        toast.error('Error, try again', { theme: 'colored' })
        console.log(e)
      })
  }
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      if (codeResponse && user) {
        setLoading(true)
        if (user.meet_bot) {
          getUser()
          createMeetUser({
            google_oauth_code: codeResponse.code,
            user: user.id
          })
          setLoading(false)
        } else {
          botService
            .createMeetBot(formDataConvert())
            .then((response) => {
              setEmailUser(codeResponse)
              getUser()
              createMeetUser({
                google_oauth_code: codeResponse.code,
                user: user ? user.id : ''
              })
              setLoading(false)
            })
            .catch((error) => {
              setLoading(false)
              console.log(error.response.data[0])
              toast.error(
                error.response ? error.response.data[0] : 'Error, try again',
                { theme: 'colored' }
              )
            })
        }
      }
    },
    onError: (error) => console.log('Login Failed:', error),
    scope: SCOPES,
    flow: 'auth-code',
    redirect_uri: process.env.REACT_APP_REDIRECT_URI
  })

  return loading ? (
    <Loading />
  ) : (
    <StyledMeetBotContainer>
      {!user?.meet_user ? (
        <React.Fragment>
          {' '}
          <StyledH2>
            In order to connect your google account to Jamy, we need:
          </StyledH2>
          <br />
          <p>
            <strong>Requirements</strong>
          </p>
          <StyledMeetBotPermissionContent>
            <StyledMeetBotPermissionText>
              Associate it with your personal information on Google
            </StyledMeetBotPermissionText>
          </StyledMeetBotPermissionContent>
          <StyledMeetBotPermissionContent>
            <StyledMeetBotPermissionText>
              View your personal information, including personal data you have
              made public
            </StyledMeetBotPermissionText>
          </StyledMeetBotPermissionContent>
          <StyledMeetBotPermissionContent>
            <StyledMeetBotPermissionText>
              View the primary email address for your Account Google
            </StyledMeetBotPermissionText>
          </StyledMeetBotPermissionContent>
          <StyledMeetBotPermissionContent>
            <StyledMeetBotPermissionText>
              View, edit and delete all files in your google drive
            </StyledMeetBotPermissionText>
            <StyledMeetBotPermissionLink href="https://accounts.google.com/">
              More information
            </StyledMeetBotPermissionLink>
          </StyledMeetBotPermissionContent>
          <StyledMeetBotPermissionContent>
            <StyledMeetBotPermissionText>
              See events in all your calendars.
            </StyledMeetBotPermissionText>
            <StyledMeetBotPermissionLink href="https://accounts.google.com/">
              More information
            </StyledMeetBotPermissionLink>
          </StyledMeetBotPermissionContent>
          <img
            src={google}
            alt="google"
            style={{ cursor: 'pointer', width: '200px' }}
            onClick={() => login()}
          ></img>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <StyledH2> You are all set to go! </StyledH2>
          <br />
          <StyledMeetBotPermissionP>
            We already connected your Google Account. You are all set to start
            using Jamy in your Google Meet meetings.
            <br /> Just remember to add <strong>notes@jamy.ai</strong> as a
            participant.
          </StyledMeetBotPermissionP>
        </React.Fragment>
      )}
    </StyledMeetBotContainer>
  )
}
export default MeetingPermissionsContainer
