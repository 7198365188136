import React from 'react'
import HelmetComponent from '../../../components/helmet'
import ResetPasswordContainer from '../../../containers/resetPasswordContainer'

const ResetPasswordPage: React.FC = () => {
  return (
    <React.Fragment>
      <HelmetComponent title="Reset Password"></HelmetComponent>
      <ResetPasswordContainer />
    </React.Fragment>
  )
}

export default ResetPasswordPage
