import React from 'react'
import {
  StyledUserTaskContent,
  StyledUserTaskDelete,
  StyledUserTaskLabel
} from './styledComponents'
import { Tooltip } from 'antd'

type PropsUserTask = {
  user: any
  isEdit?: boolean
  onDelete: (email: string) => void
}

const UserTask: React.FC<PropsUserTask> = ({ user, onDelete, isEdit }) => {
  return (
    <StyledUserTaskContent>
      <StyledUserTaskDelete
        onClick={() => onDelete(user)}
        hidden={isEdit ? false : true}
      >
        x
      </StyledUserTaskDelete>
      <StyledUserTaskLabel>
        <Tooltip title={user.email}>
          {user.email ? (
            <span>{user.email.slice(0, 2).toUpperCase()}</span>
          ) : (
            <span>
              {user.first_name.slice(0, 1).toUpperCase() +
                user.last_name.slice(0, 1).toUpperCase()}
            </span>
          )}
        </Tooltip>
      </StyledUserTaskLabel>
    </StyledUserTaskContent>
  )
}

export default UserTask
