import styled from "styled-components";

export const StyledReceiptContainer = styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
`;

export const StyledReceiptTitle = styled.h1`
  color: #5333C1;
  font-weight: 900;
  width: 100%;
  border-bottom: 2px solid black;
  margin-bottom: 20px;
`;

export const StyledReceiptItemContainer = styled.div`

p{
  margin: 0;
}
`;

export const StyledReceiptTotalContainer = styled.div`
  display: flex;
  width: 70%;
  justify-content: space-between;
  font-weight: bold;
  align-items: center;
  margin-top: 20px;
  border-top: 2px solid black;
`;

export const StyledReceiptButton = styled.button`
  background-color: #5333C1;
  color: white;
  width: 200px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;

  &:hover {
    background-color: #5333C1;
  }
`;

export const StyledReceiptLink = styled.a`
  color: #5333C1;
  cursor: pointer;
  text-decoration: underline;
  margin-right: 20px;
`;