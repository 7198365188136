import { http } from '../../config/http-common'

class MeetUsersDataService {
  getAllMeetUsers() {
    return http.get<any>(`/meet_users/`)
  }

  createMeetUser(data: any) {
    return http.post<any>('/meet_users/', data)
  }
}

export default MeetUsersDataService
