import styled from "styled-components";

export const StyledMessageBox = styled.div`
  text-align: center;
  font-size: 18px;
  background-color: #975DB8;
  padding: 20px;
  position: absolute;
  right: 15px;
  left: 15px;
  margin: 100px 15px;
  color: white;
  border-radius: 10px;
  line-height: 1.5;
`