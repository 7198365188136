import React, { useContext } from 'react'
import Menu from '../menu'
import JamyContext from '../../context/jamyContext'

const MenuContainer: React.FC = () => {
  const { logout, google_connection, slack_connection, user } =
    useContext(JamyContext)
  const accessToken = localStorage.getItem('access-token')

  const handleLogout = () => {
    if (accessToken) {
      logout({ accesToken: accessToken })
    }
  }

  return (
    <Menu
      handleLogout={handleLogout}
      google_connection={google_connection}
      slack_connection={slack_connection}
      isValidated={user ? user.is_validated : false}
    />
  )
}

export default MenuContainer
