import React from 'react'
import HelmetComponent from '../../components/helmet'
import DasboardContainer from '../../containers/dashboardContainer'
import { LayoutMain } from '../../layout'

const DashboardPage: React.FC = () => {
  return (
    <LayoutMain title={'DASHBOARD'}>
      <HelmetComponent title="Dashboard"></HelmetComponent>
      <DasboardContainer />
    </LayoutMain>
  )
}

export default DashboardPage
