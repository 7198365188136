import React from 'react'
import {
  StyledStripeResultContainer,
  StyledStripeResultPlan,
  StyledStripeResultThanks
} from './styledComponents'
import { Button, Result } from 'antd'
import logo from './../../assets/logo/logo.png'
import { useNavigate } from 'react-router-dom'

const StripeResult: React.FC = () => {
  const navigate = useNavigate()
  return (
    <StyledStripeResultContainer>
      <StyledStripeResultPlan>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <img
            src={logo}
            alt="logo"
            style={{ width: '30px', height: '30px' }}
          />
          <br></br>
          <span style={{ color: 'gray' }}>Subscribe to Pay-per-minute</span>
          <br />
          <div>
            <span style={{ fontSize: '50px', fontWeight: 'bold' }}>$0.00</span>
            <span> due today</span>
          </div>
          <br />
          <span style={{ color: 'gray' }}>
            Then $0.05 per unit, billed monthly based on usage
          </span>
        </div>
        <br />
        <div>
          <table style={{ width: '100%' }}>
            <thead>
              <tr style={{ fontWeight: 'bold' }}>
                <th style={{ width: '50%', textAlign: 'left' }}>
                  Pay-per-minute
                </th>
                <th style={{ width: '50%', textAlign: 'right' }}>
                  Price varies
                </th>
              </tr>
            </thead>
            <br />
            <tbody>
              <tr>
                <th
                  style={{
                    width: '50%',
                    textAlign: 'left',
                    fontSize: '12px',
                    color: 'gray'
                  }}
                >
                  Pay only for each minute processed by Jamy. All features
                  included, always.
                </th>
              </tr>
              <br />
              <tr>
                <th
                  style={{
                    width: '50%',
                    textAlign: 'left',
                    fontSize: '12px',
                    color: 'gray'
                  }}
                >
                  Billed monthly based on usage
                </th>
                <th
                  style={{
                    width: '50%',
                    textAlign: 'right',
                    fontSize: '12px',
                    color: 'gray'
                  }}
                >
                  $0.05 per unit
                </th>
              </tr>
            </tbody>
          </table>
          <hr />
          <table style={{ width: '100%' }}>
            <thead>
              <tr style={{ fontWeight: 'bold' }}>
                <th style={{ width: '50%', textAlign: 'left' }}>Subtotal </th>
                <th style={{ width: '50%', textAlign: 'right' }}>
                  Price varies
                </th>
              </tr>
            </thead>
            <br />
            <tbody>
              <tr>
                <th
                  style={{
                    width: '50%',
                    textAlign: 'left',
                    fontSize: '12px',
                    color: 'gray'
                  }}
                >
                  Tax
                </th>
                <th
                  style={{
                    width: '50%',
                    textAlign: 'right',
                    fontSize: '12px',
                    color: 'gray'
                  }}
                >
                  $0.00
                </th>
              </tr>
            </tbody>
          </table>
          <hr />
          <table style={{ width: '100%' }}>
            <thead>
              <tr style={{ fontWeight: 'bold' }}>
                <th style={{ width: '50%', textAlign: 'left' }}>
                  Total paid today{' '}
                </th>
                <th style={{ width: '50%', textAlign: 'right' }}>$0.00</th>
              </tr>
            </thead>
          </table>
        </div>
      </StyledStripeResultPlan>
      <StyledStripeResultThanks>
        <Result
          status="success"
          title="Thanks for subscribing"
          subTitle="A payment to Jamy Technologies, LLC will appear on your statement."
          extra={[
            <Button
              type="primary"
              htmlType="button"
              onClick={() => navigate('/welcome')}
            >
              Continue with setup
            </Button>
          ]}
        />
      </StyledStripeResultThanks>
    </StyledStripeResultContainer>
  )
}

export default StripeResult
