import React, { useEffect, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

type PropsBarChartJamy = {
  labels: string[]
  datasets: Array<{
    data: any[]
    label: string
    borderColor: string
    backgroundColor: string
  }>
}

const BarChartJamy: React.FC<PropsBarChartJamy> = ({ labels, datasets }) => {
  const [data, setData] = useState({
    labels,
    datasets: []
  })

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const
      }
    },
    scales: {
      y: {
        ticks: {
          stepSize: 1
        }
      }
    }
  }

  useEffect(() => {
    const dataSetsPrepare: any = []
    datasets.forEach((element: any) => {
      dataSetsPrepare.push({
        label: element.label,
        data: element.data,
        borderColor: element.borderColor,
        backgroundColor: element.backgroundColor,
        borderWidth: 2,
        borderRadius: 20,
        borderSkipped: false
      })
    })
    setData({ labels: labels, datasets: dataSetsPrepare })
  }, [datasets, labels])

  return <Bar options={options} data={data} />
}

export default BarChartJamy
