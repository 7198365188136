import React from 'react'
import CardIntegration from '../../cardIntegration'

type PropsCalendarBtn = {
  label: string
  icon: string
  description: string
  onClick: () => void
}

const CalendarBtn: React.FC<PropsCalendarBtn> = ({
  label,
  icon,
  onClick,
  description
}) => {
  return (
    <CardIntegration
      category="calendar"
      description={description}
      name={label}
      isConnected={false}
      onClick={onClick}
      icon={icon}
    />
  )
}

export default CalendarBtn
