/* eslint-disable react/style-prop-object */
import { Col, Row } from 'antd'
import React from 'react'

import { StyledH3, StyledH5 } from '../../styles/styledComponents'
import orgThumb from '../../assets/org/org.png'
import {
  StyledProfileContainer,
  StyledProfileImg,
  StyledProfileText
} from './styledComponents'

type PropsProfile = {
  user: User.IUserDataResponse
  org: Orgs.IOrgsResults
  onClicButtonApp: (path: string) => void
  apps: {
    google_connection: boolean
    slack_connection: boolean
  }
}

const Profile: React.FC<PropsProfile> = ({ user, org }) => {
  return (
    <StyledProfileContainer>
      <Row gutter={16}>
        <Col xs={24} sm={8} md={8} lg={4}>
          <StyledProfileImg src={orgThumb} alt="org" />
        </Col>
        <Col xs={24} sm={16} md={16} lg={20}>
          <StyledH3>{user.first_name + ' ' + user.last_name}</StyledH3>
          <StyledProfileText>{user.email}</StyledProfileText>
          <br />
          <StyledH5>Organization</StyledH5>
          <p>{org.name}</p>
        </Col>
      </Row>
    </StyledProfileContainer>
  )
}

export default Profile
