import { Button, Modal } from 'antd'
import React, { useState } from 'react'
import { StyledH2V2 } from '../../styles/styledComponents'
import { useNavigate } from 'react-router-dom'

type PropsUnlockFeature = {
  namePlan: string
  featureName: string
  showModal: boolean
  handleShowModal: () => void
  handleUpgrade?: () => void
}

const UnlockFeature: React.FC<PropsUnlockFeature> = ({
  featureName,
  showModal,
  namePlan,
  handleShowModal
}) => {
  const navigate = useNavigate()
  return (
    <Modal
      width={600}
      title={`Unlock ${featureName}`}
      open={showModal}
      onOk={handleShowModal}
      onCancel={handleShowModal}
      footer={[
        <Button key="back" onClick={handleShowModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => navigate('/pricing')}
        >
          Upgrade to {namePlan}
        </Button>
      ]}
    >
      <div style={{ textAlign: 'center', padding: '20px', margin: '30px 0' }}>
        <StyledH2V2>You've discovered an advanced feature!</StyledH2V2>
        <p style={{ fontSize: '15px' }}>
          Upgrade to {namePlan} to unlock this feature
        </p>
      </div>
    </Modal>
  )
}

export default UnlockFeature
