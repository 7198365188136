import styled from 'styled-components'

export const StyledCreateBotFormContentImg = styled.div`
  background-color: white;
  width: 100%;
  height: 350px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const StyledCreateBotFormImg = styled.img`
  width: 128px;
  height: 128px;
`

export const StyledCreateBotForm = styled.div`
  background-color: white;
  width: 100%;
  padding: 5%;
  border-radius: 15px;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const StyledCreateBotFormTitles = styled.p`
  color: #975db8;
  font-weight: 500;
  width: 100%;
`
