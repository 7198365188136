import React, { ReactElement, useEffect, useState } from 'react'
import { StyledPillContent } from './styledComponents'
import { Button, Popover } from 'antd'
import { useParams } from 'react-router-dom'
import { TooltipPlacement } from 'antd/es/tooltip'

type PropsPill = {
  name: string | undefined
  icon: string
  type: 'primary' | 'secondary' | 'default' | undefined
  content?: React.ReactNode
  onClick?: () => void
  closePopOver?: boolean
  getMeetingDetail: (meeting_id: string) => void
  placement?: TooltipPlacement
}

const Pill: React.FC<PropsPill> = ({
  name,
  icon,
  type,
  onClick,
  content,
  closePopOver,
  getMeetingDetail,
  placement
}) => {
  const [open, setOpen] = useState(false)
  const { meeting_id } = useParams()
  useEffect(() => {
    setOpen(false)
  }, [closePopOver])

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
    if (!newOpen) {
      getMeetingDetail(meeting_id ? meeting_id : '')
    }
  }

  const renderPill = () => {
    return (
      <StyledPillContent type={type} onClick={onClick}>
        <img src={icon} alt={name} width={'14px'} height={'14px'} />
        <p>{name}</p>
      </StyledPillContent>
    )
  }

  return onClick ? (
    <Popover
      content={content}
      trigger="click"
      placement={placement ? placement : 'bottom'}
      open={open}
      onOpenChange={handleOpenChange}
    >
      {renderPill()}
    </Popover>
  ) : (
    renderPill()
  )
}

export default Pill
