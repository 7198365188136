import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import QuestionsDataService from '../../services/questions'
import ChatWindow from '../../components/chatWindow'
import { useForm } from 'antd/es/form/Form'
import Loading from '../../components/loading'
import { orderBy } from 'lodash'
import JamyContext from '../../context/jamyContext'

const ChatContainer: React.FC = () => {
  const [questionForm] = useForm()
  const [questions, setQuestions] = useState<Array<any>>()
  const [loadingQuestion, setLoadingQuestion] = useState(false)
  const questionService = new QuestionsDataService()
  const { idBot, meeting_bot_id } = useParams()
  const { pathname } = useLocation()
  const { user } = useContext(JamyContext)

  const scrollToBottom = (id: string) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollTop = element.scrollHeight
    }
  }

  const getAllQuestions = () => {
    const questionsService = new QuestionsDataService()
    if (idBot) {
      questionsService
        .getQuestionByBot(idBot)
        .then((response) => {
          setQuestions(orderBy(response.data.results, 'created_date'))
          scrollToBottom('chat-id')
        })
        .catch((error) => console.error(error))
    }
  }

  const getAllQuestionsBot = () => {
    const questionsService = new QuestionsDataService()
    if (idBot) {
      questionsService
        .getQuestionByBot(idBot)
        .then((response) => {
          setQuestions(orderBy(response.data.results, 'created_date'))
          scrollToBottom('chat-id')
        })
        .catch((error) => console.error(error))
    }
  }

  useEffect(() => {
    if (idBot) {
      pathname.includes('meetChat') ? getAllQuestions() : getAllQuestionsBot()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idBot])

  const onFinish = (data: { question: string }) => {
    setLoadingQuestion(true)
    const obj: Meetings.SendQuestion = {
      question: data.question,
      bot: meeting_bot_id ? meeting_bot_id : '',
      meeting: idBot ? idBot : '',
      user: user ? user.id : ''
    }
    questionForm.resetFields()
    questionService
      .sendQuestion(obj)
      .then((response) => {
        questionForm.resetFields()
        setLoadingQuestion(false)
        getAllQuestions()
      })
      .catch((error) => {
        questionForm.resetFields()
        setLoadingQuestion(false)
        console.error(error)
      })
  }

  return (
    <React.Fragment>
      {questions && questions?.length >= 0 ? (
        <ChatWindow
          form={questionForm}
          onFinish={onFinish}
          questions={questions ? questions : []}
          loadingQuestion={loadingQuestion}
        />
      ) : (
        <Loading />
      )}
    </React.Fragment>
  )
}

export default ChatContainer
