import styled from 'styled-components'

export const StyledStepsContainer = styled.div``
export const StyledStepsItem = styled.div`
  padding: 10px 20px;
  font-weight: bold;
  font-size: 14px;
  background-color: white;
  border-radius: 10px;
  margin: 10px 0;
  height: 50px;
  display: flex;
  align-items: center;

  p {
    margin: 0 10px;
    color: lightgray;
  }
`
