import styled from 'styled-components'

export const StyledEditBotFormContentImg = styled.div`
  background-color: white;
  width: 100%;
  height: 350px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const StyledEditBotFormImg = styled.img``

export const StyledEditBotForm = styled.div`
  background-color: white;
  width: 100%;
  padding: 5%;
  border-radius: 15px;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const StyledEditBotFormTitles = styled.p`
  color: #975db8;
  font-weight: 500;
  width: 100%;
`

export const StyledEditBotFormDocumentsContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
`

export const StyledEditBotFormDocumentsImg = styled.img`
  margin: 0 5px;
`

export const StyledEditBotFormDocumentsLink = styled.a`
  color: #975db8;
`

export const StyledEditBotFormInstructionsContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
`

export const StyledEditBotFormInstructionsImg = styled.img`
  margin: 0 10px;
  cursor: pointer;
`

export const StyledEditBotFormInstructionsText = styled.p`
  margin: 0;
`
