import styled from 'styled-components'
export const StyledCreateBotsMenuContainer = styled.div``
export const StyledCreateBotsMenuImgContent = styled.div`
  display: flex;
  padding: 10% 0;
  justify-content: space-around;
`
export const StyledCreateBotsMenuImg = styled.img`
  width: 80px;
  cursor: pointer;
`
