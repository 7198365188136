import { Button, Result } from 'antd'
import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import StripeResult from '../../components/stripeResult'

const StripContainer: React.FC = () => {
  const [searchParams] = useSearchParams()
  const navigation = useNavigate()

  return !searchParams.get('update') ? (
    <StripeResult></StripeResult>
  ) : (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh'
      }}
    >
      <Result
        status="success"
        title={<p>Succesfully updated</p>}
        extra={[
          <Button
            type="primary"
            key="console"
            onClick={() => navigation('/dashboard')}
          >
            GO HOME
          </Button>
        ]}
      />
    </div>
  )
}

export default StripContainer
