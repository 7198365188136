import { http } from '../../config/http-common'

class IntegrationsDataService {
  createTrelloUser(data: { trello_token: string }) {
    return http.post<any>(`/trello_users/`, data)
  }

  createMondayUser(data: { monday_code: string }) {
    return http.post<any>(`/monday_users/`, data)
  }

  getIntegrationsBoards(app: string) {
    return http.get<
      { id: string; name: string; lists: { id: string; name: string }[] }[]
    >(`/${app}/user/boards/`)
  }

  createTrelloCard(data: {
    trello_list_id: string
    meeting_id: string
    task_id: string
  }) {
    return http.post<any>('/trello/card/', data)
  }

  createMondayCard(data: {
    monday_board_id: string
    monday_group_id: string
    meeting_id: string
    task_id: string
  }) {
    return http.post<any>('/monday/item/', data)
  }
}

export default IntegrationsDataService
