import React from 'react'
import HelmetComponent from '../../components/helmet'
import UsersContainer from '../../containers/usersContainer'
import { LayoutMain } from '../../layout'

export const UsersPage: React.FC = () => {
  return (
    <LayoutMain title="USERS">
      <HelmetComponent title="Users"></HelmetComponent>
      <UsersContainer />
    </LayoutMain>
  )
}
