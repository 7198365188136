import { Button, Form, Input, FormInstance } from 'antd'
import React from 'react'
import { StyledH1 } from '../../../styles/styledComponents'
import {
  StyledForgetPasswordFormContent,
  StyledForgetPasswordFormText
} from './styledComponents'
import IForgetPasswordUserData from '../../../types/auth/forgetPassword'
import { MailOutlined } from '@ant-design/icons'

type PropsForgetPasswordForm = {
  form: FormInstance
  onFinish: (data: IForgetPasswordUserData) => void
}
const ForgetPasswordForm: React.FC<PropsForgetPasswordForm> = ({
  form,
  onFinish
}) => {
  return (
    <StyledForgetPasswordFormContent>
      <StyledH1>RECOVER PASSWORD</StyledH1>
      <StyledForgetPasswordFormText>
        No worries! Just enter your email and we’ll send you login instructions.
      </StyledForgetPasswordFormText>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item name="email" rules={[{ required: true }]}>
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="name@jamy.ai"
          />
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => (
            <Button type="primary" htmlType="submit">
              SEND
            </Button>
          )}
        </Form.Item>
      </Form>
    </StyledForgetPasswordFormContent>
  )
}

export default ForgetPasswordForm
