export const STATUS = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  ERROR: 'ERROR'
}

export const BOT_TYPE = {
  CSV: 'TEXTO',
  PDF: 'PDF',
  MEETING: 'MEETING'
}

export const MEETING_EDIT = {
  PARTICIPANTS: 'participants',
  TASKS: 'Tasks',
  KEYWORDS: 'keywords',
  SUMMARY: 'Summary',
  TOPICS: 'Topics',
  QUESTIONS_RAISED: 'Questions_raised'
}

export const MEETING_ARGS = {
  PARTICIPANTS: 'participants',
  TASKS: 'tasks',
  KEYWORDS: 'keywords',
  SUMMARY: 'summary',
  TOPICS: 'topics',
  QUESTIONS_RAISED: 'questions_raised',
  RECIPIENTS: 'recipients'
}
