import styled from 'styled-components'

export const StyledChatWindowContainer = styled.div``
export const StyledChatWindowContent = styled.div`
  height: 80vh;
  overflow: auto;
  display: flex;
  padding: 0 2% 0 1%;
  flex-direction: column;
`
export const StyledChatWindowMessageContent = styled.div`
  display: flex;
  flex-direction: column;
`
export const StyledChatWindowMessageQuestion = styled.div`
  margin: 10px;
  width: 100%;
`
export const StyledChatWindowMessageAnswer = styled.div`
  margin: 10px;
  width: 100%;
`

export const StyledChatWindowMessageQuestionItem = styled.div`
  background-color: #fff6e0;
  padding: 10px;
  border-radius: 10px;
  max-width: 60%;
  word-wrap: break-word;
  float: right;
  text-align: end;
`
export const StyledChatWindowMessageAnswerItem = styled.div`
  background-color: #e2e7ff;
  padding: 10px;
  border-radius: 10px;
  max-width: 60%;
  word-wrap: break-word;
`

export const StyledChatWindowDateMessage = styled.div`
  font-size: 10px;
  margin: 10px 0 0 0;
  color: #b0b0b0;
  width: 100%;
  text-align: right;
`
export const StyledChatWindowUser = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #5333c1;
  text-align: start;
`
