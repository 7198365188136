import { Col, Row } from 'antd'
import { useForm } from 'antd/es/form/Form'
import React, { ReactNode, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import FooterLinks from '../../components/footerLinks'
import RegisterForm from '../../components/forms/registration'
import LefSideRegister from '../../components/leftSideRegister'
import Loading from '../../components/loading'
import UserDataService from '../../services/users'
import {
  getPercentagePassword,
  ValidatePassword
} from '../../utils/validatePassword'
import { useNavigate, useSearchParams } from 'react-router-dom'
import MeetingsDataService from '../../services/meetings'
import MessageBox from '../../components/messageBox'

const RegisterContainer: React.FC = () => {
  const [percentagePassword, setPercentagePassword] = useState(0)
  const [disableButton, setDisableButton] = useState(true)
  const [loading, setLoading] = useState(false)
  const [registerForm] = useForm<any>()
  const userService = new UserDataService()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [messageBox, setMessageBox] = useState<ReactNode>()
  const meetingDataService = new MeetingsDataService()

  const onFinish = (data: User.ICreateUserData) => {
    setLoading(true)
    if (ValidatePassword(data.password)) {
      if (data.password === data.repeatPassword) {
        userService
          .create(data)
          .then((response) => {
            registerForm.resetFields()
            setPercentagePassword(0)
            setDisableButton(true)
            setLoading(false)
          })
          .then(() => {
            toast.success('Register Success', { theme: 'colored' })
            navigate('/login')
          })
          .catch((e) => {
            toast.error(e.response.data.error, { theme: 'colored' })
            setLoading(false)
          })
      } else {
        setLoading(false)
        toast.warning('The passwords are differents', {
          theme: 'colored'
        })
      }
    } else {
      setLoading(false)
      toast.warning('The password does not meet the minimum requirements', {
        theme: 'colored'
      })
    }
  }

  const onPasswordChange = (password: string) => {
    setPercentagePassword(getPercentagePassword(password))
  }

  const onFieldsChange = () => {
    const fields: string[] = [
      'first_name',
      'last_name',
      'email',
      'organization_name',
      'password',
      'repeatPassword',
      'tyc'
    ]
    fields.forEach((element: string) => {
      if (!registerForm.getFieldValue(element)) {
        setDisableButton(true)
      } else {
        setDisableButton(false)
      }
    })
  }

  useEffect(() => {
    if (searchParams.get('email')) {
      meetingDataService
        .getMeetingBanner(searchParams.get('email'))
        .then((response) => {
          setMessageBox(createMessageBox(response.data))
        })
        .catch((e) => console.log(e))
    }
  }, [])

  const createMessageBox = (response: Meetings.BannerResponse) => {
    registerForm.setFieldValue('email', response.email)
    return (
      <p style={{ margin: '0' }}>
        <strong>Create a Jamy account for free to see the full report</strong>
      </p>
    )
  }

  return (
    <React.Fragment>
      <Row>
        <Col xs={24} sm={24} md={12} lg={12}>
          <LefSideRegister />
          <FooterLinks></FooterLinks>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <MessageBox messageBox={messageBox} />
          {!loading ? (
            <RegisterForm
              form={registerForm}
              percentage={percentagePassword}
              onFinish={onFinish}
              onPasswordChange={onPasswordChange}
              onFieldsChange={onFieldsChange}
              disableButton={disableButton}
            />
          ) : (
            <Loading />
          )}
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default RegisterContainer
