import { http } from '../../config/http-common'

class CalendarsDataService {
  getCalendar() {
    return http.get<any>('/recall/calendar/')
  }

  updatePreferences(preferences: any) {
    return http.patch<any>('/recall/calendar/', preferences)
  }

  disconectCalendar(data: { platform: string }) {
    return http.post<any>('/recall/calendar/', data)
  }

  getMeetingsByDate(year: string, month: string, day?: string) {
    return http.get<any>(
      `/calendar/meetings/${year}/${month}/${day ? day : ''}`
    )
  }

  processMeeting(recall_meeting_id: string, override_should_record: boolean) {
    return http.patch<any>(
      `/recall/meeting/${recall_meeting_id}/`, { override_should_record: override_should_record }
    )
  }
}

export default CalendarsDataService
