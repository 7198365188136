import React, { useContext } from 'react'
import JamyContext from '../../context/jamyContext'
import Organization from '../../components/organization'

const OrganizationContainer: React.FC = () => {
  const { org } = useContext(JamyContext)
  return <React.Fragment>{org && <Organization org={org} />}</React.Fragment>
}

export default OrganizationContainer
