import React from 'react'
import HelmetComponent from '../../../components/helmet'
import LoginContainer from '../../../containers/loginContainer'

const LoginPage: React.FC = () => {
  return (
    <React.Fragment>
      <HelmetComponent title="Login"></HelmetComponent>
      <LoginContainer />
    </React.Fragment>
  )
}

export default LoginPage
