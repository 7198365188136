import React from 'react'
import CardIntegration from '../../components/cardIntegration'
import teams from '../../assets/integrations/teams.svg'

const TeamsIntegration: React.FC = () => {
  return (
    <CardIntegration
      category="meetings"
      description="Jamy will join and record your Teams meetings"
      name="Teams"
      isConnected={true}
      icon={teams}
    />
  )
}

export default TeamsIntegration
