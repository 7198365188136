import React from 'react'
import {
  StyledReceiptButton,
  StyledReceiptContainer,
  StyledReceiptItemContainer,
  StyledReceiptLink,
  StyledReceiptTitle,
  StyledReceiptTotalContainer
} from './styledComponents'
import Loading from '../loading'

type PropsReceipt = {
  onClickCancel: () => void
  onClickUpgradePlan: (new_price_id: string) => void
  upgradeOverview: Princing.UpgradeOverview | undefined
}
const Receipt: React.FC<PropsReceipt> = ({
  upgradeOverview,
  onClickUpgradePlan,
  onClickCancel
}) => {
  return upgradeOverview !== undefined ? (
    <StyledReceiptContainer>
      <StyledReceiptTitle>UPGRADE OVERVIEW</StyledReceiptTitle>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            width: '70%',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '50px'
          }}
        >
          <div
            style={{ borderBottom: '2px solid black', marginBottom: '30px' }}
          >
            <StyledReceiptItemContainer>
              <p style={{ margin: 0 }}>
                Upgrade to {upgradeOverview.items[1].name}
              </p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ fontSize: '50px', fontWeight: '900', margin: 0 }}>
                  ${upgradeOverview.total.toFixed(2)}
                </p>
                <div style={{ marginLeft: '10px' }}>
                  <p style={{ margin: 0 }}>due</p>
                  <p style={{ margin: 0 }}>today</p>
                </div>
              </div>
            </StyledReceiptItemContainer>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '40px'
            }}
          >
            <StyledReceiptItemContainer>
              <p style={{ fontWeight: 'bold', fontSize: '20px' }}>
                {upgradeOverview.items[0].name}
              </p>
              <p>{upgradeOverview.items[0].description}</p>
              <p>{upgradeOverview.items[0].quantity}</p>
            </StyledReceiptItemContainer>
            <StyledReceiptItemContainer
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end'
              }}
            >
              <p style={{ fontSize: '30px', fontWeight: '900', margin: 0 }}>
                {upgradeOverview.items[0].amount.toFixed(2)}
              </p>
            </StyledReceiptItemContainer>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '40px'
            }}
          >
            <StyledReceiptItemContainer>
              <p style={{ fontWeight: 'bold', fontSize: '20px' }}>
                {upgradeOverview.items[1].name}
              </p>
              <p>{upgradeOverview.items[1].description}</p>
              <p>{upgradeOverview.items[1].quantity}</p>
            </StyledReceiptItemContainer>
            <StyledReceiptItemContainer
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end',
                justifyContent: 'center'
              }}
            >
              <p style={{ fontSize: '30px', fontWeight: '900', margin: 0 }}>
                ${upgradeOverview.items[1].amount.toFixed(2)}
              </p>
            </StyledReceiptItemContainer>
          </div>
        </div>
        <StyledReceiptTotalContainer>
          <span style={{ fontSize: '20px' }}>Total due today</span>
          <span style={{ fontSize: '40px' }}>
            ${upgradeOverview.total.toFixed(2)}
          </span>
        </StyledReceiptTotalContainer>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '50px'
        }}
      >
        <StyledReceiptButton
          onClick={() => onClickUpgradePlan(upgradeOverview.items[1].name)}
        >
          UPGRADE
        </StyledReceiptButton>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px'
        }}
      >
        <StyledReceiptLink
          onClick={() => window.open(process.env.REACT_APP_BILLING_STRIPE)}
        >
          Update Billing Info
        </StyledReceiptLink>
        <StyledReceiptLink onClick={onClickCancel}>Cancel</StyledReceiptLink>
      </div>
    </StyledReceiptContainer>
  ) : (
    <StyledReceiptContainer>
      <div style={{ height: '400px' }}>
        <Loading />
      </div>
    </StyledReceiptContainer>
  )
}

export default Receipt
