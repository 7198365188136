import React from 'react'
import { SlackIntegration } from './slack'
import TrelloIntegration from './trello'
import MondayIntegration from './monday'
import { StyledH1, StyledH3 } from '../../styles/styledComponents'
import GoogleMeetIntegration from './googleMeet'
import ZoomIntegration from './zoom'
import WebexIntegration from './webex'
import TeamsIntegration from './teams'
import CalendarConnectionContainer from '../CalendarConnectionContainer'

const IntegrationsContainer: React.FC = () => {
  const CategoryTitle = (title: string) => {
    return (
      <p
        style={{
          fontSize: '25px',
          fontWeight: 'bold',
          color: '#975DB8',
          padding: '0 30px',
          margin: '10px 0'
        }}
      >
        {title}
      </p>
    )
  }
  return (
    <div>
      <StyledH1 style={{ margin: 0, textAlign: 'center' }}>
        Supercharge Your Meetings
      </StyledH1>
      <StyledH3 style={{ margin: '10px 0', textAlign: 'center' }}>
        With Powerful Integrations
      </StyledH3>
      <p style={{ margin: '30px 0', textAlign: 'center', fontSize: '20px' }}>
        From meeting, email and assigning tasks to your teams - Jamy seamlessly
        integrates with the platforms you use daily
      </p>
      {CategoryTitle('Communication')}
      <div style={{ display: 'flex', padding: '0 20px' }}>
        <SlackIntegration />
      </div>
      {CategoryTitle('Calendar')}
      <CalendarConnectionContainer />,{CategoryTitle('Productivity')}
      <div style={{ display: 'flex', padding: '0 20px' }}>
        <TrelloIntegration />
        <MondayIntegration />
      </div>
      {CategoryTitle('Meetings')}
      <div style={{ display: 'flex', padding: '0 20px' }}>
        <GoogleMeetIntegration />
        <ZoomIntegration />
        <WebexIntegration />
        <TeamsIntegration />
      </div>
    </div>
  )
}

export default IntegrationsContainer
