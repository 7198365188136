import { http } from '../../config/http-common'

class DashboardDataService {
  getDataDashboard(year: string, month: string, user_id?: string) {
    return http.get<any>(
      `/dashboard/meetings/?year=${year}&month=${month}${
        user_id ? `&user=${user_id}` : ''
      }`
    )
  }
}

export default DashboardDataService
