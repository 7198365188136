import styled from 'styled-components'

export const StyledCalendarConnectedContent = styled.div`
  border-radius: 15px;
  border: 1px solid lightgray;
  margin-right: 20px;
  background-color: white;
  padding: 10px;
  width: 350px;
`

export const StyledCalendarConnectedHeader = styled.div`
  display: flex;
`

export const StyledCalendarConnectedContentText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`

export const StyledCalendarConnectedNameCalendar = styled.p`
  margin: 0;
  font-size: 18px;
`

export const StyledCalendarConnectedStatusCalendar = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: #e0aa25;
`

export const StyledCalendarConnectedIcon = styled.img`
  width: 50px;
`

export const StyledCalendarConnectedPermissionContainer = styled.div`
  padding: 5px;
 p{
  margin: 0;
 }
`

export const StyledCalendarConnectedPermissionContent = styled.div`
 margin-bottom: 10px;
`

export const StyledCalendarConnectedPermissionTitle = styled.label`
 font-size: 14px;
 margin: 0;
`

export const StyledCalendarConnectedPermissionDescription = styled.label`
 font-size: 10px;
 margin: 0;
`

export const StyledCalendarConnectedPermissionValue = styled.strong`
 color: #5E6DB8;
 text-decoration: underline;
 cursor: pointer;
`
