import React from 'react'
import HelmetComponent from '../../../components/helmet'
import { LayoutMain } from '../../../layout'
import MeetingDetialsContainer from '../../../containers/meetingsDetailsContainer'

const MeetingDetailPage: React.FC = () => {
  return (
    <LayoutMain title={'MEETING DETAIL'}>
      <HelmetComponent title="Meeting Detail"></HelmetComponent>
      <MeetingDetialsContainer />
    </LayoutMain>
  )
}

export default MeetingDetailPage
