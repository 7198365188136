import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
`
export const ContainerContent = styled.div`
  width: 100%;
  padding: 0 2%;
`
export const Header = styled.div``
export const Sider = styled.div``
export const Content = styled.div``
export const Notification = styled.div`
  width: 100%;
  background-color: gray;
  height: 50px;
  border-radius: 5px;
`