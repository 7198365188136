import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import BotsTable from '../../components/tables/bots'
import BotsDataService from '../../services/bots'
import { Button, Col, Modal, Row } from 'antd'
import CreateBotsMenu from '../../components/createBotsMenu'
import { PlusOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import JamyContext from '../../context/jamyContext'

const BotsContainer: React.FC = () => {
  const { google_connection, user } = useContext(JamyContext)
  const [bots, setBots] = useState<Bots.IBotsResponse>()
  const [loading, setLoading] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const navigation = useNavigate()

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const fetchData = async () => {
    const botsService = new BotsDataService()
    try {
      const response = await botsService.getAllBots()
      const meetBot = response.data.results.filter(
        (x) => x.bot_type === 'MEETING'
      )
      if (meetBot.length > 0) {
        localStorage.setItem('meetBoot', meetBot[0].id)
      }
      setBots(response.data)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      toast.error(error.message, { theme: 'colored' })
      console.error(error)
    }
  }

  const handleNavigationCreateBot = (bot_type: string) => {
    navigation(`/bots/create-bot/${bot_type.toLocaleLowerCase()}`)
  }

  useEffect(() => {
    if (loading) {
      fetchData()
    }
  }, [loading])

  return (
    <React.Fragment>
      <Row justify="end">
        <Col>
          <Button
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            onClick={showModal}
            hidden
          />
        </Col>
      </Row>
      <br />
      <BotsTable data={bots} loading={loading} />
      <Modal
        width={'70%'}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <CreateBotsMenu
          handleNavigationCreateBot={handleNavigationCreateBot}
          google_connected={google_connection}
          meetUser={user?.meet_user}
        />
      </Modal>
    </React.Fragment>
  )
}

export default BotsContainer
