import { ThemeConfig } from 'antd/es/config-provider/context'

export const theme: ThemeConfig = {
  token: {
    colorPrimary: '#E0AA25',
    fontFamily: "'Nunito', sans-serif"
  },
  components: {
    Button: {
      controlHeight: 40,
      controlHeightLG: 40
    },
    Segmented: {
      itemSelectedBg: '#5333C1',
      itemSelectedColor: '#ffffff'
    },
    Input: {
      controlHeight: 40,
      controlHeightLG: 40,
      colorPrimaryHover: '#5333C1'
    },
    InputNumber: {
      controlHeight: 40,
      controlHeightLG: 40,
      colorPrimaryHover: '#5333C1'
    },
    Select: {
      controlHeight: 40,
      controlHeightLG: 40,
      colorPrimaryHover: '#5333C1'
    }
  }
}
