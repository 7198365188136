import { useForm } from 'antd/es/form/Form'
import React, { useContext, useState } from 'react'
import CreateBotForm from '../../components/createBotForm'
import JamyContext from '../../context/jamyContext'
import BotsDataService from '../../services/bots'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Modal } from 'antd'
import { StyledH2 } from '../../styles/styledComponents'

const CreateBotContainer: React.FC = () => {
  const { org } = useContext(JamyContext)
  const navigate = useNavigate()
  const [form] = useForm()
  const [files, setFiles] = useState<any>()
  const { bot_type } = useParams()
  const [instruction, setInstruction] = useState<string>('')
  const [instructions, setInstructions] = useState<string[]>([])
  const botService = new BotsDataService()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const formDataConvert = (data: Bots.IBotsResults) => {
    let formData = new FormData()
    for (var i = 0; i < instructions.length; i++) {
      formData.append('instructions', instructions[i])
    }
    formData.append('name', data.name)
    formData.append('temperature', JSON.stringify(data.temperature))
    formData.append('organization', org ? org.id : '')
    formData.append('bot_type', bot_type ? bot_type.toLocaleUpperCase() : '')
    formData.append('certainty', JSON.stringify(data.certainty))
    formData.append('dont_know_response', data.dont_know_response)
    formData.append('description', data.description)
    if (bot_type !== 'meeting') {
      formData.append('files', files)
    }

    return formData
  }

  const onFinish = (data: Bots.IBotsResults) => {
    const obj = formDataConvert(data)
    if (org) {
      botService
        .createBot(obj)
        .then((response) => {
          if (bot_type === 'meeting') {
            showModal()
          } else {
            navigate('/bots')
          }
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }

  const addinstruction = () => {
    setInstructions([...instructions, instruction])
    form.setFieldValue('instructions', '')
    setInstruction('')
  }

  const deleteInstruction = (value: String) => {
    const instructionsArray = instructions.filter((x) => x !== value)
    setInstructions(instructionsArray)
  }

  const onChangeInstuction = (value: string) => {
    setInstruction(value)
  }

  const loadDocuments = (file: any) => {
    setFiles(file.file)
    return false
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    navigate('/bots')
  }

  return (
    <React.Fragment>
      <CreateBotForm
        botType={bot_type ? bot_type : ''}
        form={form}
        instructions={instructions}
        onFinish={onFinish}
        addinstruction={addinstruction}
        onChangeInstuction={onChangeInstuction}
        deleteInstruction={deleteInstruction}
        loadDocuments={loadDocuments}
      />
      <Modal
        width={'70%'}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <StyledH2>Great! </StyledH2>
          <p style={{ textAlign: 'justify' }}>
            Now that you already created your Organization's MeetBot, you just
            need to create your MeetUser so we can connect to your Google
            account and you can start using Jamy.ai in Google Meet
          </p>
          <Button
            onClick={() => navigate('/bots/meetPermissions')}
            type="primary"
            htmlType="button"
          >
            Connect Google Account 🚀
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default CreateBotContainer
