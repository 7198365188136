import { Button, Col, Form, FormInstance, Row, Select, Switch } from 'antd'
import React, { useContext, useEffect } from 'react'
import { optionsMeetings } from './options'
import JamyContext from '../../context/jamyContext'

type PropsCalendarConnectedEdit = {
  form: FormInstance
  onFinish: (data: any) => void
  onCancel: () => void
  preferences: any
}

const CalendarConnectedEdit: React.FC<PropsCalendarConnectedEdit> = ({
  form,
  onFinish,
  onCancel,
  preferences
}) => {
  const { user } = useContext(JamyContext)
  useEffect(() => {
    form.setFieldValue('join', preferences.value)
    form.setFieldValue('isEnable', !user?.automatic_notification)
  }, [])

  return (
    <Form form={form} style={{ marginTop: '20px' }} onFinish={onFinish}>
      <Row gutter={16} justify="space-around">
        <Col span={12}>
          <p>Jamy will join</p>
        </Col>
        <Col span={12}>
          <Form.Item name="join">
            <Select>
              {optionsMeetings.map((item, key) => {
                return (
                  <Select.Option key={key} value={item.value}>
                    {item.text}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {/* <Row gutter={16}>
        <Col span={12}>
          <p>
            Meeting notes will be sent to host for review before sending to rest
            of participants
          </p>
        </Col>
        <Col span={12}>
          <Form.Item
            name={'isEnable'}
            valuePropName="checked"
            style={{ display: 'flex', justifyContent: 'end' }}
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row> */}
      <Row gutter={16} justify="end">
        <Button
          type="text"
          htmlType="button"
          style={{ width: '30%', marginRight: '10px' }}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" style={{ width: '30%' }}>
          Save
        </Button>
      </Row>
    </Form>
  )
}

export default CalendarConnectedEdit
