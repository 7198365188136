import React, { useState } from 'react'
import { StyledH2 } from '../../styles/styledComponents'
import { Button, Col, Form, FormInstance, Input, Row, Tag } from 'antd'
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'

type PropsCustomWords = {
  custom_words: string[]
  deleteItem: (incorrect: string, word: string) => void
  onClicItem: (word: string) => void
  onFinishWord: (data: any) => void
  form: FormInstance
  word: string
}

const CustomWords: React.FC<PropsCustomWords> = ({
  custom_words,
  form,
  word,
  onFinishWord,
  onClicItem,
  deleteItem
}) => {
  const [itemSelected, setItemSelected] = useState<boolean>(true)
  return (
    <div>
      <div style={{ marginTop: '20px', marginLeft: '20px' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '10px'
          }}
        >
          <StyledH2 style={{ margin: '0' }}>{word}</StyledH2>
          <PlusCircleOutlined
            hidden={word == ''}
            style={{
              marginLeft: '10px',
              cursor: 'pointer',
              color: '#5333c1'
            }}
            onClick={() => {
              setItemSelected(!itemSelected)
              onClicItem(word)
            }}
          />
        </div>
        <div hidden={itemSelected}>
          <Form form={form} onFinish={onFinishWord}>
            <Form.Item name="customWord">
              <Row gutter={16}>
                <Col span={12}>
                  <Input placeholder={`Add new custom word to ${word}`}></Input>
                </Col>
                <Col span={2}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() => setItemSelected(!itemSelected)}
                  >
                    +
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </div>
        <div style={{ display: 'flex' }}>
          {custom_words.map((incorrect, index) => {
            return (
              <Tag color="#5333c1" style={{ display: 'flex' }} key={index}>
                {incorrect}
                <div>
                  <CloseCircleOutlined
                    style={{ margin: '5px', cursor: 'pointer' }}
                    onClick={() => {
                      deleteItem(incorrect, word)
                    }}
                  />
                </div>
              </Tag>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default CustomWords
