import { Button, Col, Form, FormInstance, Input, InputNumber, Row } from 'antd'
import React from 'react'
import {
  StyledEditBotFormInstructionsContent,
  StyledEditBotFormInstructionsImg,
  StyledEditBotFormInstructionsText
} from '../editBotForm/styledComponents'
import UploadDragger from '../upload'
import {
  StyledCreateBotForm,
  StyledCreateBotFormContentImg,
  StyledCreateBotFormImg,
  StyledCreateBotFormTitles
} from './styledComponents'
import bote from '../../assets/icons/delete.png'
import bot from '../../assets/bots/bot.png'

type PropsCreateBotForm = {
  form: FormInstance
  botType: string
  onFinish: (data: Bots.IBotsResults) => void
  instructions: string[]
  onChangeInstuction: (value: string) => void
  addinstruction: () => void
  deleteInstruction: (value: String) => void
  loadDocuments: (file: any) => void
}

const CreateBotForm: React.FC<PropsCreateBotForm> = ({
  form,
  instructions,
  onChangeInstuction,
  onFinish,
  addinstruction,
  deleteInstruction,
  loadDocuments,
  botType
}) => {
  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={6} lg={8}>
        <StyledCreateBotFormContentImg>
          <StyledCreateBotFormImg src={bot} />
        </StyledCreateBotFormContentImg>
      </Col>
      <Col xs={24} sm={24} md={18} lg={16}>
        <Form form={form} layout="inline" onFinish={onFinish}>
          <StyledCreateBotForm>
            <div>
              <Row gutter={16}>
                <StyledCreateBotFormTitles>
                  Información general de BOT
                </StyledCreateBotFormTitles>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item name="name">
                    <Input placeholder="Bot name" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6}>
                  <Form.Item name="temperature">
                    <InputNumber
                      min={0.0}
                      max={1}
                      step={0.1}
                      placeholder="Temperature"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6}>
                  <Form.Item name="certainty">
                    <InputNumber
                      min={0.0}
                      max={1}
                      step={0.1}
                      placeholder="Certainty"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <br />
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item name="dont_know_response">
                    <Input placeholder="Dont know response" />
                  </Form.Item>
                </Col>
              </Row>
              <br />
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item name="description">
                    <Input.TextArea placeholder="Description" />
                  </Form.Item>
                </Col>
              </Row>
              <br />
              {botType !== 'meeting' && (
                <React.Fragment>
                  <Row gutter={16}>
                    <StyledCreateBotFormTitles>
                      Upload documents (Format PDF or CSV)
                    </StyledCreateBotFormTitles>
                    <Row gutter={16} style={{ width: '100%' }}>
                      <Col span={24}>
                        <UploadDragger
                          loadDocuments={loadDocuments}
                        ></UploadDragger>
                      </Col>
                    </Row>
                  </Row>
                  <br />
                  <br />
                </React.Fragment>
              )}

              <Row gutter={1}>
                <Col span={12}>
                  <Form.Item name="instructions">
                    <Input
                      placeholder="Instructions"
                      onChange={(e) => onChangeInstuction(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={addinstruction}
                  >
                    +
                  </Button>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  {instructions.map((item, index) => {
                    return (
                      <StyledEditBotFormInstructionsContent key={index}>
                        <StyledEditBotFormInstructionsText>
                          {item}
                        </StyledEditBotFormInstructionsText>
                        <StyledEditBotFormInstructionsImg
                          src={bote}
                          onClick={() => deleteInstruction(item)}
                        />
                      </StyledEditBotFormInstructionsContent>
                    )
                  })}
                </Col>
              </Row>
              <br />
              <br />
            </div>
            <div>
              <Row gutter={16} justify="end">
                <Col span={6}>
                  <Button
                    type="default"
                    htmlType="button"
                    style={{ width: '100%' }}
                  >
                    CANCEL
                  </Button>
                </Col>
                <Col span={6}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: '100%' }}
                  >
                    CREATE
                  </Button>
                </Col>
              </Row>
            </div>
          </StyledCreateBotForm>
        </Form>
      </Col>
    </Row>
  )
}

export default CreateBotForm
